// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// Traducciones
const resources = {
  en: {
    translation: {
      "player": "Player",
      "code": "Code",
      // Añade más traducciones aquí
    }
  },
  es: {
    translation: {
      "player": "Jugador",
      "code": "Codigo",
      // Añade más traducciones aquí
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en", // Idioma por defecto
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
