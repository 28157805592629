import React, { useEffect, useState, useRef } from "react";
import { Stage, Layer, Line, Text, Circle, Group, Image as KonvaImage, Rect } from "react-konva";

const Flight = () => {
    const initialInvestment=100, maxTime=100;
  const [currentInvestment, setCurrentInvestment] = useState(initialInvestment);
  const [currentTime, setCurrentTime] = useState(0);
  const [isExploded, setIsExploded] = useState(false);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);
  const [way, setWay] = useState([{ x: 0, y: 590 }]);
  const [explosionTime, setExplosionTime] = useState(maxTime * Math.random());
  const [intervalId, setIntervalId] = useState(null);
const [dimenssions, setDimenssions] =useState({width: window.innerWidth, height: window.innerHeight})
const [increment, setIncement] = useState({x:window.innerWidth*0.1, y: window.innerHeight*0.1})
  const stageRef = useRef(null);

  const high = (x) => {
    return -Math.pow(x, 2) /  + dimenssions.height;
  };

  
useEffect(()=>{
    console.log(way)
},[way])
  useEffect(() => {
    const updateFlightChart = () => {
      const ctx = stageRef.current.getStage();
      ctx.clear();

      if (!isExploded && !hasWithdrawn) {
        setCurrentInvestment((prev) => prev + 0.002 * prev);

        let currentX = currentTime * (dimenssions.width / maxTime);
        let currentY = high(currentX);
        setWay((prevWay) => [...prevWay, { x: parseInt(currentX), y: parseInt(currentY) }]);

        if (currentTime >= explosionTime && !hasWithdrawn) {
          setIsExploded(true);
        }

        if (!isExploded) {
          setCurrentTime((prev) => prev + 0.1);
        }
      }
    };

    const interval = setInterval(updateFlightChart, 100);
    setIntervalId(interval);

    return () => clearInterval(interval);
  }, [currentTime, isExploded, hasWithdrawn, explosionTime]);

  const restartGame = () => {
    setCurrentInvestment(initialInvestment);
    setCurrentTime(0);
    setIsExploded(false);
    setHasWithdrawn(false);
    setWay([{ x: 0, y: dimenssions.height - 10 }]);
    setExplosionTime(maxTime * Math.random());
  };

  const handleWithdraw = () => {
    if (!hasWithdrawn) {
      setHasWithdrawn(true);
    }
  };

  return (
    <>
      <Stage
        width={dimenssions.width}
        height={dimenssions.height}
        ref={stageRef}
      >
        <Layer>
          <Group
            x={0}
            y={0}
            width={dimenssions.width}
            height={dimenssions.height}
          >
            <Rect
              x={0}
              y={0}
              width={dimenssions.width}
              height={dimenssions.height}
              fill={"black"}
              stroke={"grey"}
              strokeWidth={5}
            />
            {/**grupo para encabezado */}
            <Group
              y={0}
              x={0}
              width={dimenssions.width}
              height={dimenssions.height * 0.1}
              fill={""}
              stroke={"grey"}
              strokeWidth={5}
            >
              <Rect
                x={0}
                y={0}
                width={dimenssions.width}
                height={dimenssions.height * 0.1}
                fill={""}
                stroke={"grey"}
                strokeWidth={5}
              />
            </Group>
            <Group>
              <Line
                points={way.flatMap((point) => [point.x, point.y])}
                stroke="white"
                strokeWidth={2}
                lineCap="round"
                lineJoin="round"
              />
              {!isExploded && (
                <Circle
                  x={way[way.length - 1].x}
                  y={way[way.length - 1].y}
                  radius={10}
                  fill="red"
                />
              )}
              {hasWithdrawn && (
                <Text
                  text={`Inversión retirada a los ${currentTime.toFixed(
                    2
                  )} segundos`}
                  x={way[way.length - 1].x}
                  y={way[way.length - 1].y}
                  fill="white"
                />
              )}
              {isExploded && (
                <Text
                  text={`¡Explotó a los ${explosionTime.toFixed(2)} segundos!`}
                  x={way[way.length - 1].x}
                  y={way[way.length - 1].y}
                  fill="red"
                />
              )}
              <Text
                text={`Tiempo: ${currentTime.toFixed(2)} segundos`}
                x={10}
                y={10}
                fill="white"
              />
              <Text
                text={`x: ${currentInvestment.toFixed(3)}`}
                x={10}
                y={30}
                fill="white"
              />
            </Group>
          </Group>
        </Layer>
      </Stage>
      <button onClick={handleWithdraw}>Retirar Inversión</button>
    </>
  );
};

export default Flight;
