import React, {useState} from 'react'
import Quagga from 'quagga';


function Pay() {
    const [qr,SetQr] = useState();
    Quagga.init({
        inputStream : {
          name : "Live",
          type : "LiveStream",
        //  target: document.querySelector('#yourElement')    // Or '#yourElement' (optional)
        },
        decoder : {
          readers : ["code_128_reader"]
        }
      }, function(err) {
          if (err) {
              console.log(err);
              return
          }
          console.log("Initialization finished. Ready to start");
          Quagga.start();
      });
  return (
    <div>Pay</div>
  )
}

export default Pay