import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { headers, url } from "../../utils/const";
import { Link } from "react-router-dom";

const Player = () => {
  const [amountRetiro, setAmountRetiro] = useState();
  const [playerToPay, setPlayerToPay] = useState();
  const [disabled, setDisabled] = useState(false);
  const [credit, setCredit] = useState({ amount: "", player: {} });
  const [show, setShow] = useState(false);
  const [showRetiro, setShowRetiro] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [name, setName] = useState("");
  const [customer, setCustomer] = useState("");
  const [point, setPoint] = useState({});
  const [level, setLevel] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState({ init: "", end: "" });
  const [listPlayer, setListPlayer] = useState([]);
  const [playerAddCredit, setPlayerAddCredit] = useState();
  const [filter, setFilter] = useState("");
  const [player, setPlayer] = useState({
    name: "",
    document: {
      type: "cedula",
      number: "",
    },
    phone: "",
    email: "",
    point: localStorage.getItem("point"),
    register: localStorage.getItem("user"),
  });
useEffect(() => {
  if (localStorage.getItem("level") !== "4")
    return (window.location.href = "/");
}, []);
  useEffect(() => {
    (async () => {
      const res = await fetch(`${url}/player/data`, {
        method: "get",
        headers: headers,
      });
      const data = await res.json();
      setListPlayer(data.player);
    })();
  }, [setListPlayer]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCredit = () => setShowCredit(false);
  const handleShowRetiro = (element) => {
    setAmountRetiro(() => {
      return element.credit.retiro.find((ret) => ret.paid === false).amount;
    });
    setPlayerToPay(element);
    setShowRetiro(true);
  };
  const handleCloseRetiro = () => setShowRetiro(false);
  const handleShowCredit = (element) => {
    setPlayerAddCredit(element);
    setShowCredit(true);
    setDisabled(false);
  };

  const OnChangeCustomer = (e) => setCustomer(e.target.value);
  const OnChangePlayerName = (e) =>
    setPlayer({ ...player, name: e.target.value });
  const OnChangePlayerDocumentType = (e) =>
    setPlayer({
      ...player,
      document: { type: e.target.value, number: player.document.number },
    });
  const OnChangePlayerDocumentNumber = (e) =>
    setPlayer({
      ...player,
      document: { number: e.target.value, type: player.document.type },
    });
  const OnChangePlayerPhone = (e) =>setPlayer({ ...player, phone: e.target.value });
  const OnChangePlayerEmail = (e) => setPlayer({ ...player, email: e.target.value });
  const OnChangePlayerAddCrdit = (e) => setCredit({ player: playerAddCredit, amount: e.target.value });
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);

  const CreatePlayer = async () => {
    const res = await fetch(`${url}/player`, {
      method: "post",
      headers: headers,
      body: JSON.stringify(player),
    });
    const data = await res.json();
    if (data.hasOwnProperty("player")) {
      setListPlayer(data.player);
      alert(data.msg);
      setPlayer({
        name: "",
        document: {
          type: "cedula",
          number: "",
        },
        phone: "",
        email: "",
        point: localStorage.getItem("point"),
        register: localStorage.getItem("user"),
      });
      handleClose();
    }
  };

  /**Agregar Credito */
  const AddCredit = async () => {
    setDisabled(true);
    const res = await fetch(`${url}/player/add-credit`, {
      method: "post",
      headers: headers,
      body: JSON.stringify(credit),
    });
    const data = await res.json();
    alert(data.msg);
    handleCloseCredit();
    window.location.reload();
  };

  /**Pagar Credito */
  const PayCredit = async () => {
    setDisabled(true);
    const res = await fetch(`${url}/player/retiro-credito`, {
      method: "post",
      headers: headers,
      body: JSON.stringify({ player: playerToPay, amount: amountRetiro }),
    });
    const data = await res.json();
    alert(data.msg);
    handleCloseCredit();
    window.location.reload();
  };

  const filteredPlayers = listPlayer.filter((player) =>
    player.name.toLowerCase().includes(filter.toLowerCase()) ||player.phone.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="container-fluid card">
      <div className="card-header col-12 row justify-content-between">
        <div className="col-4">Control de Jugadores</div>
        <div className="col-2">
          <Link to="/bet" className="btn btn-success form-control">
            Ir a Venta
          </Link>
        </div>
      </div>
      <div className="card-body">
        <div className="col-12 row">
          <div className="col-8"></div>
          {/*  <div className="col-4">
         <button className="btn btn-primary" onClick={handleShow}>
              Registrar Jugador
            </button>
          </div>*/}
        </div>
        <div className="col-12 row justify-content-end">
          <div className="col-sm-4 col-md-2 pb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Filtrar por nombre o Celular"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="table-resonsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Num</th>
                <th>Nombre</th>
                <th>Telefono</th>
                <th>Credito</th>
                <th>Estado</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              {filteredPlayers.length > 0 ? (
                filteredPlayers.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>
                      {item.credit.balance.toFixed(2)}{" "}
                      {item.credit.retiro.some((ret) => ret.paid === false) ? (
                        <button onClick={() => handleShowRetiro(item)}>
                          Retirar
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{item.activated ? "Activo" : "Desactivado"}</td>
                    <td>
                      <div>
                        <button
                          className="btn btn-success"
                          onClick={() => handleShowCredit(item)}
                        >
                          Add Credito
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No se encontraron jugadores</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={show} size="lg" className="bg-info" centered>
        <Modal.Header closeButton>
          <Modal.Title>Registrar Nuevo Jugador</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="col-12 row">
              <div className="col-6 form-group">
                <div className="col-12 row">
                  <div className="col-6">
                    <label className="col-12">Tipo Documento</label>
                    <select
                      className="form-select"
                      value={player.document.type}
                      onChange={OnChangePlayerDocumentType}
                    >
                      <option value={"1"}>Cedula</option>
                      <option value={"2"}>Pasaporte</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      <label>Numero Documento</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={player.document.number}
                      onChange={OnChangePlayerDocumentNumber}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-6 form-group">
                <div className="col-12">
                  <label>Nombre Del Jugador</label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    value={player.name}
                    onChange={OnChangePlayerName}
                  ></input>
                </div>
              </div>
              <div className="col-6 form-group">
                <div className="col-12">
                  <label>Telefono(Celular)</label>
                </div>
                <div className="col-12">
                  <input
                    type="phone"
                    className="form-control"
                    value={player.phone}
                    onChange={OnChangePlayerPhone}
                  ></input>
                </div>
              </div>
              <div className="col-6 form-group">
                <div className="col-12">
                  <label>Correo Electronico:</label>
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control"
                    value={player.email}
                    onChange={OnChangePlayerEmail}
                  ></input>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose}>Close</button>
            <button type="button" onClick={CreatePlayer}>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showCredit} size="sm" className="bg-info" centered>
        <Modal.Header>
          <Modal.Title>
            <div className=""> Agregar Credito:</div>
            <div className="">{playerAddCredit ? playerAddCredit.name : ""}</div>
            <div className="">Celular</div>
            <div className="">{playerAddCredit ? playerAddCredit.phone : ""}</div>
            
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="col-12 row">
              <div className="col-12 form-group">
                <div className="col-12 row">
                  <div className="col-12">
                    <div className="col-12">
                      <label>Monto (RD$.)</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={credit.amount}
                      onChange={OnChangePlayerAddCrdit}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={handleCloseCredit}>Cerrar</button>
            <button type="button" onClick={AddCredit} disabled={disabled}>
              Agregar
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showRetiro} size="sm" className="bg-info" centered>
        <Modal.Header>
          <Modal.Title>Retiro de Credito</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="col-12 row">
              <div className="col-12 form-group">
                <div className="col-12 row">
                  <div className="col-12">
                    Nombre: {playerToPay ? playerToPay.name : ""}
                  </div>
                  <div className="col-12">
                    Documento: {playerToPay ? playerToPay.document.number : ""}
                  </div>
                  <div className="col-12">
                    <div className="col-12">
                      <label>Monto RD$.: {amountRetiro}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={handleCloseCredit}>Cerrar</button>
            <button type="button" onClick={PayCredit} disabled={disabled}>
              Pagar
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Player;
