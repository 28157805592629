// src/PayPalButton.js
import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalPaymentButton = () => {
    const initialOptions = {
      clientId: "test",
      currency: "USD",
      intent: "capture",
    };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons />
    </PayPalScriptProvider>
  );
};

export default PayPalPaymentButton;
