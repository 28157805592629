// src/BankTransferForm.js
import React, { useState } from "react";
import axios from "axios";

const BankTransferForm = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/api/transfer", { accountNumber, amount })
      .then((response) => {
        alert("Transferencia iniciada");
      })
      .catch((error) => {
        console.error("Error en la transferencia", error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        placeholder="Número de cuenta"
      />
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Monto"
      />
      <button type="submit">Transferir</button>
    </form>
  );
};

export default BankTransferForm;
