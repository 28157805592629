import React from 'react'
import { PenFill } from 'react-bootstrap-icons';

function TableCustomer(list) {
    const customerList = list.list;
    return (
        <div className='table-responsive pt-2 mt-2'>
            <table className='table table-borded pt-2 mt-3'>
                <thead className=''>
                    <tr>
                        <th>Num</th>
                        <th>Codigo</th>
                        <th>Nombre</th>
                        <th>RNC</th>
                        <th>Telefono</th>
                        <th>Email</th>
                        <th>Direccion</th>
                        <th>Representante</th>
                        <th>Documento</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th>Posicion</th>
                        <th>Usuario</th>
                        <th>Creado Por</th>
                        <th>Accion</th>
                    </tr>
                </thead>
                <tbody>
                {customerList.map(z =>
                    <tr key={z.num}>
                        <td>{z.code}</td>
                        <td>{z.num}</td>
                        <td><div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox"  checked={z.state}/>
                    <label class="form-check-label" for="flexSwitchCheckChecked">{z.name}</label>
                </div></td>
                        <td>{z.rnc}</td>
                        <td>{z.phone}</td>
                        <td>{z.email}</td>
                        <td>{z.address}</td>
                        <td>{z.agent.name}</td>
                        <td>{z.agent.documentId}</td>
                        <td>{z.agent.phone}</td>
                        <td>{z.agent.email}</td>
                        <td>{z.agent.position}</td>
                        <td>{z.agent.user}</td>
                        <td>{z.registedBy || ''}</td>
                        <td>
                        <button className=''><PenFill/></button>
                        </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default TableCustomer