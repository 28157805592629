import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Bet from './components/bet/bet';
import Admin from './components/admin/Admin';
import Login from './components/users/Login';
import Pay from './components/Pay';
import Dashboad from './components/dashboard/Dashboard';
import Point from './components/admin/points/Point';
import Customer from './components/admin/customer/Customer';
import User from './components/admin/user';
import Venta from './components/bet/venta';
import Result from './components/results/Result';
import Historial from './components/bet/historial';
import  Player  from './components/players/player';
import  AdminPlayer  from './components/players/admin-player';
import Checkout from './components/pays/pays.jsx'
import  {register} from './serviceWorkerRegistration'; // Cambia 'serviceWorker' a 'serviceWorkerRegistration'
import './i18n'; 
import Machine from './components/machine/machine';
import TheQueen from './components/the-queen/queen';
import Mina from './components/busca-mina/mina';
import LotteryTicket from './components/the-queen/bet.jsx';
import Ruleta from './components/ruleta/ruleta.jsx';
import Flight from './components/fligth/fligth.jsx';
import LoteryBet from './components/lotery/bet.jsx';
import RouletteWheel from './components/ruleta-three/ruleta-three.jsx';
import Sports from './components/sports/sports.jsx';
import BetSport from './components/sports/bet/bet-sport.jsx';
import SlotGame from './components/slot/slot.jsx';
import BetDogSix from './components/bet/bet-dogs-six.js';
import DashboardDogSix from './components/dashboard/Dashboard-dog-six.js';
const root = ReactDOM.createRoot(document.getElementById('root')); 
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/pay" element={<Pay />}></Route>
        <Route path="/line" element={<Dashboad />}></Route>
        <Route path="/line-dog-six" element={<DashboardDogSix />}></Route>
        <Route path="/history" element={<Historial />}></Route>
        <Route path="/venta" element={<Venta />}></Route>
        <Route path="/bet" element={<Bet />}></Route>
        <Route path="/bet-dog-six" element={<BetDogSix />}></Route>
        <Route path="/admin-player" element={<AdminPlayer />}></Route>
        <Route path="/player" element={<Player />}></Route>
        <Route path="/admin/" element={<Admin />}>
          <Route path="points" element={<Point />}></Route>
          <Route path="users" element={<User />}></Route>
          <Route path="customer" element={<Customer />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/dashboard" element={<Dashboad />}></Route>
        <Route path="/machine" element={<Machine />}></Route>
        <Route path="/queen" element={<TheQueen />}></Route>
        <Route path="/mina" element={<Mina />}></Route>
        <Route path="/payment" element={<Checkout />}></Route>
        <Route path="/lotery-bet" element={<LoteryBet />}></Route>
        <Route path="/ruleta" element={<Ruleta />}></Route>
        <Route path="/fligth" element={<Flight />}></Route>
        <Route path="/rulet-three" element={<RouletteWheel />}></Route>
        <Route path="/sports" element={<Sports />}>
          <Route to="bet" element={<BetSport />} />
        </Route>
        <Route path="/slot" element={<SlotGame />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
register();
