import React, { useEffect, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three";
import * as p2 from "p2";
import { Circle, Stage, Layer, Text } from "react-konva";

const RouletteWheel = () => {
  const wheelRef = useRef();
  const ballRef = useRef();
  const worldRef = useRef(new p2.World({ gravity: [0, -9.82] }));
  const wheelBodyRef = useRef(new p2.Body({ mass: 0 }));
  const ballBodyRef = useRef(new p2.Body({ mass: 1, position: [0, 2] }));

  useEffect(() => {
    // Initialize P2 physics
    const world = worldRef.current;
    const wheelBody = wheelBodyRef.current;
    const ballBody = ballBodyRef.current;
    const wheelShape = new p2.Circle({ radius: 3 });
    const ballShape = new p2.Circle({ radius: 0.1 });

    wheelBody.addShape(wheelShape);
    ballBody.addShape(ballShape);

    world.addBody(wheelBody);
    world.addBody(ballBody);

    // Add a contact material to simulate friction
    const contactMaterial = new p2.ContactMaterial(
      wheelShape.material,
      ballShape.material,
      {
        friction: 0.3,
        restitution: 0.6,
      }
    );
    world.addContactMaterial(contactMaterial);

    // Simulate the world
    const simulate = () => {
      world.step(1 / 60);
      if (ballRef.current) {
        ballRef.current.position.set(
          ballBody.position[0],
          ballBody.position[1],
          0.1
        );
        ballRef.current.rotation.z = ballBody.angle;
      }
    };

    const interval = setInterval(simulate, 1000 / 60);
    return () => clearInterval(interval);
  }, []);

  const texture = new TextureLoader().load("../../assets/ruleta.png"); // Replace with your texture path

  return (
    <>
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <mesh ref={wheelRef}>
          <circleGeometry args={[3, 64]} />
          <meshBasicMaterial map={texture} />
        </mesh>
        <mesh ref={ballRef}>
          <sphereGeometry args={[0.1, 32, 32]} />
          <meshBasicMaterial color="white" />
        </mesh>
        <WheelRotation wheelRef={wheelRef} />
      </Canvas>
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <Layer>
          <Circle
            x={window.innerWidth / 2}
            y={window.innerHeight / 2}
            radius={150}
            fill="green"
          />
          {/* Add numbers and other elements with Konva here */}
        </Layer>
      </Stage>
    </>
  );
};

const WheelRotation = ({ wheelRef }) => {
  useFrame(() => {
    if (wheelRef.current) {
      wheelRef.current.rotation.z += 0.02;
    }
  });
  return null;
};

export default RouletteWheel;
