import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Keyboard = () => {
  const handleClick = (value) => {
    console.log(value);
  };

  const renderKey = (value, extraClass = "") => (
    <button
      type="button"
      className={`btn btn-secondary m-1 ${extraClass}`}
      onClick={() => handleClick(value)}
    >
      {value}
    </button>
  );

  return (
    <div className="container">
      <div className="row">
        {["1", "2", "3"].map((num) => (
          <div className="col" key={num}>
            {renderKey(num)}
          </div>
        ))}
      </div>
      <div className="row">
        {["4", "5", "6"].map((num) => (
          <div className="col" key={num}>
            {renderKey(num)}
          </div>
        ))}
      </div>
      <div className="row">
        {["7", "8", "9"].map((num) => (
          <div className="col" key={num}>
            {renderKey(num)}
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col">{renderKey("0")}</div>
      </div>
      <div className="row">
        {["R", "H", "P"].map((letter) => (
          <div className="col" key={letter}>
            {renderKey(letter)}
          </div>
        ))}
      </div>
      <div className="row">
        {["K", "S"].map((letter) => (
          <div className="col" key={letter}>
            {renderKey(letter)}
          </div>
        ))}
      </div>
      <div className="row">
        {["+", "-"].map((sign) => (
          <div className="col" key={sign}>
            {renderKey(sign)}
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col">{renderKey("Borrar", "btn-danger")}</div>
        <div className="col">{renderKey("Enter", "btn-success")}</div>
      </div>
    </div>
  );
};

export default Keyboard;
