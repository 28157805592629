import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { headers, url } from '../../../utils/const';
import TableCustomer from './table-customer';

function Customer() {
    const [show, setShow] = useState(false);
    const [list, setList]= useState([]);
    const [agent, setAgent] = useState({
        name: '',
        documentId: '',
        phone: '',
        email: '',
        position: '',
        user: '',
        password: '',
        repeatPassword: ''
    })
    const [customer, setCustomer] = useState({
        rnc: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        agent: {}
    })
    useEffect(()=>{
        fetch(`${url}/customer`,{
            method: 'get',
           headers: headers
        }).then(res => res.json()).then(data => {
            if(Array.isArray(data)){
                setList(data)
            } else{
                
            }
        })
    },[])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const OnchangeRnc = (e) => setCustomer({ ...customer, rnc: e.target.value });
    const OnchangePhone = (e) => setCustomer({ ...customer, phone: e.target.value });
    const OnchangeEmail = (e) => setCustomer({ ...customer, email: e.target.value });
    const OnchangeAddress = (e) => setCustomer({ ...customer, address: e.target.value });
    const OnchangeName = (e) => setCustomer({ ...customer, name: e.target.value });
    const OnchangeAgentName = (e) => setAgent({ ...agent, name: e.target.value });
    const OnchangeAgentDocumentId = (e) => setAgent({ ...agent, documentId: e.target.value });
    const OnchangeAgentPhone = (e) => setAgent({ ...agent, phone: e.target.value });
    const OnchangeAgentEmail = (e) => setAgent({ ...agent, email: e.target.value });
    const OnchangeAgentPosition = (e) => setAgent({ ...agent, position: e.target.value });
    const OnchangeAgentUser= (e) => setAgent({ ...agent, user: e.target.value });
    const OnchangeAgentPassword = (e) => setAgent({ ...agent, password: e.target.value});
    const OnchangeAgentRepeatPassword = (e) => setAgent({ ...agent, repeatPassword: e.target.value});
    const CreateCustomer = () => {
        setCustomer({ ...customer, agent: agent })
        fetch(`${url}/customer`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(customer)
        }).then(res => res.json()).then(data => {
            if (!data.includes('Error')) {
                setCustomer({
                    rnc: '',
                    name: '',
                    phone: '',
                    email: '',
                    address: '',
                    agent: {}
                })
                setAgent({
                    name: '',
                    documentId: '',
                    phone: '',
                    email: '',
                    position: '',
                    user: '',
                    password: '',
                    repeatPassword: ''
                })
setList(data);
            } else {
// completar si hay error
            }
        })
    }
    return (
        <div className='card'>

            <div className='p-2 m-2 justify-content-end row col-12 card-header'>
                <div className='col-4'>
                    <button onClick={handleShow} className="btn btn-primary pt-2 mt-2">
                        Registrar Nuevo Cliente
                    </button>
                </div>
            </div>
            <div className='col-12 m-2 p-2 card-body'>
                <TableCustomer list={list}/>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" className='bg-info'>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Nuevo cliente</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className='col-12 row'>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>RNC o Cedula</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={customer.rnc} onChange={OnchangeRnc}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Nombre Comercial</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={customer.name} onChange={OnchangeName}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Telefono</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={customer.phone} onChange={OnchangePhone}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Email</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={customer.email} onChange={OnchangeEmail}></input></div>
                            </div>
                            <div className='col-12 form-group'>
                                <div className='col-12'><label>Direccion</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={customer.address} onChange={OnchangeAddress}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Nombre representante</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.name} onChange={OnchangeAgentName}></input></div>
                            </div>

                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Documento:</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.documentId} onChange={OnchangeAgentDocumentId}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Celular</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.phone} onChange={OnchangeAgentPhone}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Email</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.email} onChange={OnchangeAgentEmail}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Posicion</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.position} onChange={OnchangeAgentPosition}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                                <div className='col-12'><label>Usuario</label></div>
                                <div className='col-12'><input type="text" className='form-control' value={agent.user} onChange={OnchangeAgentUser}></input></div>
                            </div>
                            <div className='col-6 form-group'>
                            <div className='col-12'><label>Password</label></div>
                            <div className='col-12'><input type="password" className='form-control' value={agent.password} onChange={OnchangeAgentPassword}></input></div>
                        </div>
                        <div className='col-6 form-group'>
                        <div className='col-12'><label>Repetir Password</label></div>
                        <div className='col-12'><input type="password" className='form-control' value={agent.repeatPassword} onChange={OnchangeAgentRepeatPassword}></input></div>
                    </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleClose}>
                            Close
                        </button>
                        <button type='button' onClick={CreateCustomer}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
}

export default Customer