import React, { useEffect, useState } from 'react'
import './Bono.css';

function Bonos(bono) {
    const [value, setValue] = useState();
    useEffect(()=>{
       if(bono && bono.value === 2){
        setValue('X2')
       }else if(bono && bono.value === 3){
        setValue('X3')
       }
    },[value, bono])
  
    return (
        <div className="flotante_bono">
            <div className="flot1"></div>
            <div className="fila"><div className="corrien_dogs"><p className="corri">Corrien</p><p className="can">DOGS</p></div></div>
            <div className="flot1"></div>

            <div className="flot1"></div>
            <div className="fila1"><p className="bonusflot bonflo"><b>BONO</b></p></div>
            <div className="flot1"></div>

            <div className="flot1"></div>
            <div className="fila2"><p className="bonusflot equflo"><b>{value}</b></p></div>
            <div className="flot1"></div>

            <div className="flot1"></div>
            <div className="flot1"></div>
            <div className="flot1"></div>
        </div>
    )
}

export default Bonos