import React from 'react'

function JackpotLocal(props) {
  return (
    <div className='contJacpot'>
      <div className='gridJacpot'>
        <div className='textJack'>Jackpot</div>
        <div className='textTipo'>
          <spam className='textLcl'>Local</spam>
          </div>
        <div className='textMonto'>$ {props.value}</div>
        <div className='textTicket'>Ticket: {props.ticket}</div>
      </div>
    </div>
  )
}

export default JackpotLocal