// src/Checkout.js
import React from "react";
import PaymentForm from "./strike";
import PayPalPaymentButton from "./paypall";
import BankTransferForm from "./transfer";

const Checkout = () => {
  return (
    <div>
      <h1>Pago</h1>
      <PaymentForm />
      <hr />
      <PayPalPaymentButton/>
      <hr />
      <BankTransferForm />
    </div>
  );
};

export default Checkout;
