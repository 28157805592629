import React from 'react'
import { Link } from 'react-router-dom'
import Keyboard from '../../utils/key-board';

function Sports() {
  return (
    <div>
      <nav className="nav bg-primary col-12 justify-content-between row">
        <Link className="text-white col p-2 m-2 fs-3">Jugar</Link>
        <Link className="text-white col p-2 m-2 fs-3">Linea</Link>
        <Link className="text-white col p-2 m-2 fs-3">Tickets</Link>
        <Link className="text-white col p-2 m-2 fs-3">Result</Link>
        <Link className="text-white col p-2 m-2 fs-3">Jugar</Link>
        <Link className="text-white col p-2 m-2 fs-3" to="/bet">
          Exit
        </Link>
      </nav>
      <Keyboard/>
    </div>
  );
}

export default Sports