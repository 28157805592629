import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Lines from "../lines/Lines";
import Racing from "../racing/Racing";
import Result from '../results/Result';
import { socket } from "../../io";
import Bonos from '../bonos/Bonos'
import Jackpots from "../jackpots/Jackpots";
import { Logout } from "../../utils/logout";
import Machine from "../machine/machine";

function Dashboard() {
  const [run, setRun] = useState();
  const [screen, setScreen] = useState(4);
  const [time, setTime] = useState();
  const [paidJp, setPaidJp] = useState(false);
  const [jackpotToPay, setJackpotToPay] = useState({ value: 0, type: '' });

  useEffect(() => {
    socket.on('run', (dataRun) => {
      setRun(dataRun.run);
    });

    socket.on('get', (data) => {
      setScreen(value => data && data.screen && data.screen !== value ? data.screen : value);
      setTime(value => data && data.time && data.time !== value ? data.time : value);
    });

    socket.on("screen", (data) => {
      setScreen(value => data && data.screen && data.screen !== value ? data.screen : value);
      if (data.time) {
        setTime(value => data.time !== value ? data.time : value);
      }
    });

    socket.on('jackpot', (data) => {
      if (data && data.winner && data.winner.jackpot && data.winner.jackpot.value > 0) {
        setPaidJp(true);
        setJackpotToPay({
          point: data.winner.jackpot.point,
          ticket: data.ticket,
          value: data.winner.jackpot.value,
          type: data.winner.jackpot.type,
          customer: data.winner.jackpot.customer // Asegúrate de que 'customer' exista en 'data.winner.jackpot'
        });
      }
    });
  }, [time, screen, paidJp, jackpotToPay, run]);

  if (!localStorage.getItem('user')) {
    return window.location.href = '/';
  }

  switch (screen) {
    case 1:
      return (
        <div className="containerLine">
          <Lines screen={1} />
        </div>
      );
    case 2:
      return <Racing />;
    case 3:
      return (
        <div className="containerLine"> 
          <Lines screen={3} />
        </div>
      );
    case 4:
      if (run && run.factor && run.factor.value > 1) {
        return (
          <div className="containerLine">
            <Bonos className="position-absolute" {...run.factor} />
          </div>
        );
      } else {
        return (
          <div className="containerLine">
            <div className="error position-absolute w-100 text-align-center">
              <div className="fs-1">
                Próxima Carrera.......en {time} Seg. {!time ? <button className="btn btn-link btn-sm" onClick={Logout}>Go</button> : ''}
              </div>
            </div>
          </div>
        );
      }
    case 5:
      if (jackpotToPay.type === 'general' ||
          (jackpotToPay.point && localStorage.getItem('point') && jackpotToPay.point === localStorage.getItem('point')) ||
          (jackpotToPay.type === 'CONSORCIO' && localStorage.getItem('customer') && jackpotToPay.customer === localStorage.getItem('customer'))) {
        return (
          <div className="containerLine">
            <Jackpots {...jackpotToPay} />
          </div>
        );
      } else {
        return (
          <div className="containerLine">
            <Lines screen={3} />
          </div>
        );
      }
    default:
      return <div></div>;
  }
}

export default Dashboard;
