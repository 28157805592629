import React from "react";
import { url } from "../../utils/const";

const SlotGame = () => {
  return <div>
    <iframe
    src={`${url}/slot`}
    width={window.innerWidth}
    height={window.innerHeight}
    title="Casino"
    />
  </div>;
};

export default SlotGame;
