import React, { useEffect, useRef , useState} from "react";
import { Layer, Stage, Group, Rect, Text, Circle } from "react-konva";
import * as p2 from "p2";

const TheQueen = () => {

  const stageRef = useRef(null);
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [simulationActive, setSimulationActive] = useState(true);

  useEffect(() => {
    const world = new p2.World({
      gravity: [0, -9.82],
    });

    // Create ground body
    const groundBody = new p2.Body({
      mass: 0, // Mass = 0 makes the body static
      position: [0, -1],
    });
    const groundShape = new p2.Plane();
    groundBody.addShape(groundShape);
    world.addBody(groundBody);

    // Create circle body
    const circleBody = new p2.Body({
      mass: 1,
      position: [0, 3],
    });
    const circleShape = new p2.Circle({ radius: 0.5 });
    circleBody.addShape(circleShape);
    world.addBody(circleBody);

    // Set restitution (bounciness) for the circle shape
    circleShape.material = new p2.Material();
    const groundMaterial = new p2.Material();
    groundShape.material = groundMaterial;

    const contactMaterial = new p2.ContactMaterial(circleShape.material, groundMaterial, {
      restitution: 0.9, // Adjust this value for more or less bounciness
    });
    world.addContactMaterial(contactMaterial);

    const timeStep = 1 / 60;

    const update = () => {
      if (simulationActive) {
        world.step(timeStep);
        setCirclePosition({
          x: circleBody.position[0],
          y: circleBody.position[1],
        });
      }
      requestAnimationFrame(update);
    };

    update();
  }, [simulationActive]);

  const handleSwitchChange = () => {
    setSimulationActive(prevState => !prevState);
  };

  return (
    <div>
      <label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            checked={simulationActive}
            onChange={handleSwitchChange}
          />
        </div>
        Activar/Desactivar Simulación
      </label>
      <Stage width={800} height={600} ref={stageRef}>
        <Layer>
          {/* Ground */}
          <Rect
            x={0}
            y={500} // Adjusted for the ground position
            width={800}
            height={10}
            fill="black"
          />
          {/* Falling Circle */}
          <Circle
            x={circlePosition.x * 100 + 400} // Adjusted for center and scaling
            y={500 - circlePosition.y * 100} // Adjusted for scaling and inverting y-axis
            radius={50}
            fill="red"
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default TheQueen;
