import React, { useCallback, useEffect, useState } from "react";
import {
  Circle,
  Group,
  Layer,
  Stage,
  Rect,
  Text,
  Line,
  Image as KonvaImage,
  Wedge
} from "react-konva";
import bgMachine from "../../assets/venta/maq.png";
import sensorImg from "../../assets/venta/sensor.png";
import { socket } from "../../io";
import { headers, url } from "../../utils/const";

const Machine = () => {
  const [dimenssion, setDimenssion] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [soundBet] = useState(new Audio('../../assets/sounds/apostar.mp3'))
  const [soundGirar] = useState(new Audio('../../assets/sounds/gira.mp3'))
  const [update, setUpdate] = useState(true);
  const [points, setPoints] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(null);
  const [animationRunning, setAnimationRunning] = useState(false);
  const [finalNumber, setFinalNumber] = useState(null);
  const [lastWinner, setLastWinner] = useState([]);
  const [sorteo, setSorteo] = useState(0);
  const [bg, setBg] = useState();
 // const [credito, setCredito] = useState();
  const [win, setWin] = useState(0);
  const [intervalTime, setIntervalState] = useState(50);
  const [sensor, setSensor] = useState();
  const [amountThisBet, setAmountThisBet] = useState(0);
  const [factorBet, setFactorBet] = useState(1)
  const [player, setPlayer] =useState({credit:0, jpm:0, bonus:0})
  const [numbers, setNumber] = useState([
    { index: 0, value: 0, color: "green", bet: 0 },
    { index: 1, value: 5, color: "red", bet: 0 },
    { index: 2, value: 12, color: "black", bet: 0 },
    { index: 3, value: 3, color: "red", bet: 0 },
    { index: 4, value: 10, color: "black", bet: 0 },
    { index: 5, value: 1, color: "red", bet: 0 },
    { index: 6, value: 8, color: "black", bet: 0 },
    { index: 7, value: 9, color: "red", bet: 0 },
    { index: 8, value: 2, color: "black", bet: 0 },
    { index: 9, value: 7, color: "red", bet: 0 },
    { index: 10, value: 6, color: "black", bet: 0 },
    { index: 11, value: 11, color: "red", bet: 0 },
    { index: 12, value: 4, color: "black", bet: 0 },
  ]);
  useEffect(() => {
    const img = new window.Image();
    img.src = bgMachine;
    img.onload = setBg(img);
  }, []);
      useEffect(() => {
      }, [finalNumber, intervalTime]);
    useEffect(() => {
    }, [factorBet]);
  useEffect(() => {
    const img = new window.Image();
    img.src = sensorImg;
    img.onload = setSensor(img);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setDimenssion({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimenssion]);

  useEffect(() => {
    const numSegments = 13;
    const radius = dimenssion.width * 0.25;
    const centerX = dimenssion.width / 2;
    const centerY = dimenssion.height / 2;
    const deltaTheta = (2 * Math.PI) / numSegments;
    const newPoints = numbers.map((item, index) => {
      const theta = index * deltaTheta;
      const x = centerX + radius * Math.cos(theta - Math.PI / 2);
      const y = centerY + radius * Math.sin(theta - Math.PI / 2);
      return { ...item, x, y };
    });

    if (JSON.stringify(points) !== JSON.stringify(newPoints)) {
      setPoints(newPoints);
    }
  }, [dimenssion, points]);
useEffect(()=>{
  if(animationRunning ===false && update===true){
    fetch(`${url}/player-data`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (
          typeof data === "object" &&
          data !== null &&
          data.hasOwnProperty("credit")
        ) {
          setPlayer(() => data);
          setLastWinner(()=> data.lastWinners)
          setUpdate(false)
        }
      });
  }
},[player, update])

  const startAnimation = useCallback(() => {
    if (numbers.some((item) => item.bet > 0 && amountThisBet <= player.credit)) {
      setPlayer(actual=> {return{...actual, credit: actual.credit-amountThisBet}})
      fetch(`${url}/machine/new-round`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({points:
          points.map((z) => {
            return { value: z.value, bet: z.bet };
          })
      }),
      })
        .then((res) => res.json())
        .then((data) => {
          const betThis = points.reduce((acum, actual) => acum + actual.bet, 0);
         // setCredito((actual) => actual - betThis);
          setSorteo((actual) => actual + 1);
          setAnimationRunning(true);
          setFinalNumber(null);
          setWin(0);

          const totalRounds = 10; // 10 times plus a random fraction
          let count = 0;
          const interval = setInterval(() => {
            count++;
            setHighlightIndex((prevIndex) => {
              const newIndex = prevIndex === null ? 0 : count % points.length;
          //  soundGirar.play().catch(error=> console.log(error))
              if (count>= totalRounds*13 && numbers[newIndex].value== data.finalWinner) {
                clearInterval(interval);
                setAnimationRunning(false);
                setFinalNumber(data.finalWinner);
                setLastWinner([]);
                setLastWinner(()=>data.lastWinners);
                setIntervalState(50);
                /**Selecciona el ganador y a;ade credito */
                if (points && points[newIndex] && points[newIndex].value) {
                  const numberWinner = points.find(
                    (item) =>
                      item.value === points[newIndex].value && item.bet > 0
                  );
                  if (numberWinner) {
                    setWin(0);
                    const winThisBet = numberWinner.bet * 12;
                    setWin(() => data.winner);
                  //  setCredito((actualCredito) => actualCredito + winThisBet);
                  }
                }
                    fetch(`${url}/player-data`, {
                      method: "GET",
                      headers: headers,
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        if (
                          typeof data === "object" &&
                          data !== null &&
                          data.hasOwnProperty("credit")
                        ) {
                          setPlayer(() => data);
                          setUpdate(true)
                        }
                      });
                return newIndex;
              }
              return newIndex;
            });
          }, intervalTime);
        }).catch(error=>{
          console.log(error)
        });
    }
  }, [
    amountThisBet,
    player,
    numbers,
    points,
    intervalTime,
    sorteo,
  ]);
  const handleAddBet = useCallback(
    (index) => {
      const totalBetted = numbers.reduce(
        (acum, actual) => acum + actual.bet,
        0
      );
      if (totalBetted + factorBet <= player.credit && animationRunning ===false) {
        const numberThisBet = numbers.find(item => item.value===index)
        numbers[numbers.indexOf(numberThisBet)].bet = numbers[numbers.indexOf(numberThisBet)].bet + factorBet;
        setPoints(numbers);
        setAmountThisBet(totalBetted + factorBet);
      //  soundBet.play().catch(error=> console.log(error))
      } else {
        //TODO:: No tiene credito suficente
      }
    },
    [player, numbers, factorBet]
  );

  /**Borrar seleccion */
  const DeleteSelect = useCallback(() => {
    setNumber([
      {index:0, value: 0, color: "green", bet: 0 },
      {index:1, value: 5, color: "red", bet: 0 },
      {index:2, value: 12, color: "black", bet: 0 },
      {index:3, value: 3, color: "red", bet: 0 },
      {index:4, value: 10, color: "black", bet: 0 },
      {index:5, value: 1, color: "red", bet: 0 },
      {index:6, value: 8, color: "black", bet: 0 },
      {index:7, value: 9, color: "red", bet: 0 },
      {index:8, value: 2, color: "black", bet: 0 },
      {index:9, value: 7, color: "red", bet: 0 },
      {index:10, value: 6, color: "black", bet: 0 },
      {index:11, value: 11, color: "red", bet: 0 },
      {index:12, value: 4, color: "black", bet: 0 },
    ]);
    setPoints(numbers);
  }, [numbers]);
  /**Slect win */
/**Factor de apuesta */
const handleFactor =useCallback(()=>{
  setFactorBet(previoValue =>{
  if (previoValue === 1) {
    return 5
  } else if (previoValue=== 5) {
    return 10
  } else {
    return  1
  }
  })

},[])
  if (dimenssion.width > dimenssion.height) {
    return (
      <div className="fs-1 text-white">
        Favor colocar el dispositivo de forma vertical
      </div>
    );
  } else {
    return (
      <Stage width={dimenssion.width} height={dimenssion.height}>
        <Layer>
          <Group
            x={0}
            y={0}
            width={dimenssion.width}
            height={dimenssion.height}
          >
            <KonvaImage
              Image={bg}
              x={0}
              y={0}
              width={dimenssion.width}
              height={dimenssion.height}
            />
            <KonvaImage
              Image={sensor}
              x={dimenssion.width * 0.825}
              y={dimenssion.height * 0.06}
              width={dimenssion.width * 0.1}
              height={dimenssion.height * 0.15}
            />
            <Group
              x={0}
              y={0}
              width={dimenssion.width}
              height={dimenssion.height * 0.05}
            >
              <Group
                x={0}
                y={0}
                width={dimenssion.width}
                height={dimenssion.height * 0.05}
              >
                <Rect
                  x={dimenssion.width / 4}
                  y={dimenssion.height * 0.03}
                  width={dimenssion.width / 2}
                  height={dimenssion.height * 0.03}
                  fill="blue"
                />
              </Group>
              <Text
                text={"LAST WINNERS"}
                x={0}
                y={dimenssion.height * 0.03}
                width={dimenssion.width}
                height={dimenssion.height * 0.03}
                fill="yellow"
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
              />
              {lastWinner.map((item, index) => (
                <Group
                  x={(index + 1) * dimenssion.width * 0.1}
                  y={dimenssion.height * 0.065}
                  width={dimenssion.width * 0.08}
                  height={dimenssion.height * 0.05}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={dimenssion.width * 0.08}
                    height={dimenssion.height * 0.05}
                    fill="black"
                    stroke="black"
                    strokeWidth={4}
                    opacity={0.7}
                    cornerRadius={5}
                  />
                  <Text
                    text={item}
                    x={0}
                    y={0}
                    width={dimenssion.width * 0.08}
                    height={dimenssion.height * 0.05}
                    fontSize={dimenssion.width / 17}
                    align="center"
                    verticalAlign="middle"
                    fill="red"
                    stroke={"red"}
                  />
                </Group>
              ))}
            </Group>
            <Group x={dimenssion.width * 0.15} y={dimenssion.height * 0.05}>
              <Circle
                x={dimenssion.width * 0.35}
                y={dimenssion.height * 0.25}
                radius={dimenssion.width * 0.35}
                fill="gray"
                stroke={"yellow"}
                strokeWidth={10}
                opacity={1}
              />
              <Circle
                x={dimenssion.width * 0.35}
                y={dimenssion.height * 0.25}
                radius={dimenssion.width * 0.19}
                fill="brown"
                stroke={"black"}
                opacity={0.9}
              />
              {/**  la ruleta con wedge */}

              {points.map(({ x, y, color, value, index }) => (
                <Group
                  key={index}
                  x={dimenssion.width * 0.35}
                  y={dimenssion.height * 0.25}
                  radius={dimenssion.width * 0.07}
                  rotation={(index * 360) / 13 - 90 - 360 / 26}
                >
                  <Wedge
                    x={0}
                    y={0}
                    radius={dimenssion.width * 0.3}
                    angle={360 / 13}
                    fill={index === highlightIndex ? "yellow" : color}
                    stroke={"white"}
                    strokeWidth={5}
                  />
                </Group>
              ))}
              {points &&
                points.map(({ x, y, value }, index) => (
                  <Group
                    key={`${index}-1`}
                    x={x - dimenssion.width * 0.175}
                    y={y - dimenssion.height * 0.26}
                  >
                    <Text
                      text={value}
                      x={0}
                      y={0}
                      fontSize={dimenssion.width / 20}
                      fill={index === highlightIndex ? "black" : "white"}
                      stroke={index === highlightIndex ? "black" : "white"}
                      align="center"
                      verticalAlign="middle"
                      strokeWidth={1.5}
                    />
                  </Group>
                ))}
              <Circle
                x={dimenssion.width * 0.35}
                y={dimenssion.height * 0.25}
                radius={dimenssion.width * 0.15}
                fill="brown"
                stroke={"white"}
                align={"center"}
                strokeWidth={5}
              />
            </Group>

            {finalNumber !== null && (
              <Group
                x={dimenssion.width * 0.3}
                y={dimenssion.height * 0.225}
                width={dimenssion.width * 0.4}
                height={dimenssion.width * 0.3}
              >
                <Rect
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.4}
                  height={dimenssion.width * 0.3}
                  fill={win.state === true ? "green" : "red"}
                  stroke={win.state === true ? "white" : "orange"}
                  strokeWidth={4}
                  cornerRadius={10}
                  opacity={0.9}
                />
                <Text
                  text={`NUMBER WINNER:`}
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.4}
                  height={dimenssion.width * 0.075}
                  fontSize={dimenssion.width / 30}
                  align="center"
                  verticalAlign="middle"
                  fill="white"
                />
                <Rect
                  x={dimenssion.width * 0.155}
                  y={dimenssion.width * 0.055}
                  width={dimenssion.width * 0.125}
                  height={dimenssion.width * 0.1}
                  fill={"black"}
                  opacity={0.7}
                  cornerRadius={5}
                />
                <Text
                  text={` ${finalNumber}`}
                  x={0}
                  y={dimenssion.width * 0.075}
                  width={dimenssion.width * 0.4}
                  height={dimenssion.width * 0.075}
                  fontSize={dimenssion.width / 10}
                  align="center"
                  verticalAlign="middle"
                  fill="yellow"
                />
                <Text
                  text={
                    win.state === true
                      ? win.factor > 1
                        ? `WIN: $${win.amountWinner}x${win.factor}`
                        : `WIN: $${win.amountWinner}`
                      : "LOST"
                  }
                  x={0}
                  y={dimenssion.width * 0.125}
                  width={dimenssion.width * 0.4}
                  height={dimenssion.width * 0.15}
                  fontSize={dimenssion.width / 20}
                  align="center"
                  verticalAlign="middle"
                  fill={win.amountWinner > 0 ? "white" : "yellow"}
                />
                {win.state === true && win.factor > 1 && (
                  <Text
                    text={`$${win.amountToPay}`}
                    x={0}
                    y={dimenssion.width * 0.2}
                    width={dimenssion.width * 0.4}
                    height={dimenssion.width * 0.15}
                    fontSize={dimenssion.width / 20}
                    align="center"
                    verticalAlign="middle"
                    fill={win.amountWinner > 0 ? "white" : "yellow"}
                  />
                )}
              </Group>
            )}
          </Group>
          {/**Vista apuestas y Start/Cancel*/}
          {!animationRunning && (
            <Group x={dimenssion.width * 0.78} y={dimenssion.height * 0.5}>
              <Group x={0} y={dimenssion.height * 0.055}>
                <Circle
                  x={dimenssion.width * 0.07}
                  y={dimenssion.height * 0.07}
                  radius={dimenssion.width * 0.05}
                  fill="yellow"
                  stroke={"brown"}
                  strokeWidth={5}
                  onClick={handleFactor}
                  onTouchStart={handleFactor}
                />
                <Rect
                  x={0}
                  y={dimenssion.height * 0.01}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={20}
                  align="center"
                  verticalAlign="middle"
                  fill="black"
                  opacity={0.3}
                  cornerRadius={5}
                  onClick={handleFactor}
                  onTouchStart={handleFactor}
                />
                <Text
                  text={`X$${factorBet}`}
                  x={0}
                  y={dimenssion.height * 0.01}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={dimenssion.width / 20}
                  align="center"
                  stroke={"white"}
                  strokeWidth={1.2}
                  verticalAlign="middle"
                  fill="white"
                  onClick={handleFactor}
                  onTouchStart={handleFactor}
                />
              </Group>
              <Group x={0} y={dimenssion.width * 0.325}
              >
                <Circle
                  x={dimenssion.width * 0.07}
                  y={dimenssion.height * 0.07}
                  radius={dimenssion.width * 0.05}
                  fill="blue"
                  stroke={"yellow"}
                  strokeWidth={5}
                  onClick={startAnimation}
                  onTouchStart={startAnimation}
                />
                <Rect
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={20}
                  align="center"
                  verticalAlign="middle"
                  fill="black"
                  opacity={0.3}
                  cornerRadius={5}
                  onClick={startAnimation}
                  onTouchStart={startAnimation}
                />
                <Text
                  text="Start"
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={20}
                  align="center"
                  verticalAlign="middle"
                  fill="white"
                  stroke={"white"}
                  strokeWidth={1.3}
                  onClick={startAnimation}
                  onTouchStart={startAnimation}
                />
              </Group>

              <Group x={0} y={dimenssion.height * 0.25}>
                <Circle
                  x={dimenssion.width * 0.07}
                  y={dimenssion.width * 0.17}
                  radius={dimenssion.width * 0.05}
                  fill="red"
                  strokeWidth={5}
                  stroke={"orange"}
                  onClick={DeleteSelect}
                  onTouchStart={DeleteSelect}
                />
                <Rect
                  x={0}
                  y={dimenssion.width * 0.04}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={dimenssion.width / 20}
                  opacity={0.4}
                  cornerRadius={5}
                  align="center"
                  verticalAlign="middle"
                  fill="black"
                  onClick={DeleteSelect}
                  onTouchStart={DeleteSelect}
                />
                <Text
                  text="Cancel"
                  x={0}
                  y={dimenssion.width * 0.04}
                  width={dimenssion.width * 0.17}
                  height={dimenssion.width * 0.07}
                  fontSize={dimenssion.width / 20}
                  align="center"
                  verticalAlign="middle"
                  fill="white"
                  stroke={"white"}
                  strokeWidth={1.3}
                  onClick={DeleteSelect}
                  onTouchStart={DeleteSelect}
                />
              </Group>
              <Group x={0} y={dimenssion.height * 0.3}>
                <Rect
                  x={0}
                  y={dimenssion.width * 0.17}
                  width={dimenssion.width * 0.15}
                  height={dimenssion.width * 0.1}
                  stroke={"black"}
                  cornerRadius={5}
                  fill="brown"
                  strokeWidth={5}
                  onClick={() => (window.location.href = "/admin-player")}
                  onTouchStart={() => (window.location.href = "/admin-player")}
                />
                <Text
                  text="Exit"
                  x={0}
                  y={dimenssion.width * 0.17}
                  width={dimenssion.width * 0.15}
                  height={dimenssion.width * 0.1}
                  fontSize={dimenssion.width / 20}
                  align="center"
                  verticalAlign="middle"
                  fill="white"
                  onClick={() => (window.location.href = "/admin-player")}
                  onTouchStart={() => (window.location.href = "/admin-player")}
                />
              </Group>
            </Group>
          )}
          <Group
            x={dimenssion.width * 0.1}
            y={dimenssion.height * 0.51}
            width={dimenssion.width * 0.65}
            height={dimenssion.height * 0.1}
          >
            <Rect
              x={0}
              y={0}
              width={dimenssion.width * 0.65}
              height={dimenssion.height * 0.1}
              stroke={"gray"}
              strokeWidth={5}
              cornerRadius={5}
              fill="green"
              opacity={0.8}
            />

            {points.map(({ x, y, color, value, bet }, index) => (
              <Group
                key={index}
                x={
                  index === 0
                    ? 0
                    : (index - 6 * Math.floor(index / 6.1)) *
                      dimenssion.width *
                      0.09
                }
                y={
                  index === 0
                    ? dimenssion.height * 0.025
                    : Math.floor(index / 7) * dimenssion.height * 0.05
                }
                width={dimenssion.width * 0.07}
                height={dimenssion.height * 0.05}
              >
                <Rect
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.07}
                  height={dimenssion.height * 0.03}
                  fill={color}
                  opacity={0.9}
                />
                <Text
                  text={bet}
                  x={0}
                  y={0}
                  fontSize={dimenssion.width / 30}
                  width={dimenssion.width * 0.07}
                  height={dimenssion.height * 0.03}
                  align="center"
                  verticalAlign="middle"
                  fill={bet > 0 ? "yellow" : "white"}
                  stroke={bet > 0 ? "yellow" : "white"}
                />
                <Text
                  text={value}
                  x={0}
                  y={dimenssion.height * 0.03}
                  fontSize={dimenssion.width / 30}
                  width={dimenssion.width * 0.07}
                  height={dimenssion.height * 0.02}
                  align="center"
                  verticalAlign="middle"
                  fill="black"
                  stroke={"black"}
                  strokeWidth={1.1}
                />
              </Group>
            ))}
          </Group>
          {/**Botones de apuesta */}
          <Group
            x={dimenssion.width * 0.1}
            y={dimenssion.height * 0.63}
            width={dimenssion.width * 0.65}
            height={dimenssion.height * 0.42}
          >
            <Rect
              x={0}
              y={0}
              width={dimenssion.width * 0.65}
              height={dimenssion.height * 0.32}
              fill="green"
              stroke={"yellow"}
              strokeWidth={5}
              cornerRadius={5}
              opacity={0.2}
            />
            {points.map(({ x, y, color, value, index }) => (
              <Group
                key={index}
                x={
                  index === 0
                    ? (dimenssion.width * 0.65) / 2 - dimenssion.width * 0.05
                    : dimenssion.width * 0.05 +
                      (index - 4 * Math.floor(index / 4.1) - 1) *
                        dimenssion.width *
                        0.15
                }
                y={
                  index === 0
                    ? dimenssion.height * 0.01
                    : dimenssion.height * 0.01 +
                      Math.floor(index / 4.1 + 1) * dimenssion.height * 0.08
                }
                width={dimenssion.width * 0.07}
                height={dimenssion.height * 0.03}
                onClick={() => handleAddBet(index)}
                onTouchStart={() => handleAddBet(index)}
              >
                <Rect
                  x={0}
                  y={0}
                  width={dimenssion.width * 0.1}
                  height={dimenssion.width * 0.1}
                  fill={color}
                  cornerRadius={50}
                  stroke={"black"}
                  strokeWidth={2}
                />
                <Text
                  text={index}
                  x={0}
                  y={0}
                  fontSize={dimenssion.width / 20}
                  width={dimenssion.width * 0.1}
                  height={dimenssion.width * 0.1}
                  align="center"
                  verticalAlign="middle"
                  fill="white"
                  stroke={"white"}
                  strokeWidth={1.5}
                />
              </Group>
            ))}
          </Group>
          {/**dDatos de Juagador y Credito */}
          <Group
            x={dimenssion.width * 0.76}
            y={dimenssion.height * 0.41}
            width={dimenssion.width * 0.2}
            height={dimenssion.width * 0.36}
          >
            <Group
              x={0}
              y={dimenssion.height * 0.025}
              width={dimenssion.width * 0.2}
              height={dimenssion.width * 0.12}
            >
              <Rect
                x={0}
                y={0}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.07}
                fill="black"
                cornerRadius={0}
                opacity={0.5}
              />
              <Text
                text={player.credit.toString().padStart(4, "0")}
                x={0}
                y={0}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.07}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="white"
              />
              <Rect
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fill="gray"
              />
              <Text
                text="Credit"
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="red"
              />
            </Group>
            {/*<Group
              x={0}
              y={dimenssion.width * 0.13}
              width={dimenssion.width * 0.2}
              height={dimenssion.width * 0.07}
            >
              <Rect
                x={0}
                y={0}
                width={dimenssion.width * 0.25}
                height={dimenssion.width * 0.07}
                fill="black"
                cornerRadius={0}
                opacity={0.5}
              />
              <Text
                text={
                  win && win.amountToPay
                    ? win.amountToPay.toString().padStart(4, "0")
                    : "0000"
                }
                x={0}
                y={0}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.07}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="white"
              />
              <Rect
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fill="gray"
              />
              <Text
                text="WIN"
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="red"
              />
            </Group>*/}
            <Group
              x={0}
              y={2 * dimenssion.width * 0.095}
              width={dimenssion.width * 0.2}
              height={dimenssion.width * 0.07}
            >
              <Rect
                x={0}
                y={0}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.07}
                fill="black"
                cornerRadius={0}
                opacity={0.5}
              />
              <Text
                text={
                  player.bonus < 1
                    ? player.bonus.toFixed(2)
                    : player.bonus.toFixed(0)
                }
                x={0}
                y={0}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.07}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="white"
              />
              <Rect
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fill="gray"
              />
              <Text
                text="BONUS"
                x={0}
                y={dimenssion.width * 0.07}
                width={dimenssion.width * 0.2}
                height={dimenssion.width * 0.05}
                fontSize={dimenssion.width / 20}
                align="center"
                verticalAlign="middle"
                fill="red"
              />
            </Group>
          </Group>
          <Group
            x={dimenssion.width * 0.05}
            y={dimenssion.height * 0.125}
            width={dimenssion.width * 0.2}
            height={dimenssion.height * 0.3}
          >
            <Text
              text="JackPot"
              x={0}
              y={0}
              width={dimenssion.width * 0.2}
              height={dimenssion.height * 0.2}
              fontSize={dimenssion.width / 20}
              stroke={"yellow"}
            />
            <Rect
              x={0}
              y={dimenssion.height * 0.03}
              width={dimenssion.width * 0.12}
              height={dimenssion.height * 0.03}
              fill="black"
            />
            <Text
              text={
                player.jp < 1 ? player?.jp?.toFixed(2) : player?.jp?.toFixed(0)
              }
              x={0}
              y={dimenssion.height * 0.03}
              width={dimenssion.width * 0.12}
              height={dimenssion.height * 0.03}
              fill="white"
              align="center"
              verticalAlign="middle"
              fontSize={dimenssion.width / 20}
              stroke={"white"}
              strokeWidth={1.2}
            />
            <Rect
              x={dimenssion.width * 0.01}
              y={dimenssion.height * 0.1}
              width={dimenssion.width * 0.07}
              height={dimenssion.height * 0.03}
              fill={win && win.factor == 2 ? "green" : "red"}
              stroke={"yellow"}
            />
            <Text
              text={"x2"}
              x={0}
              y={dimenssion.height * 0.1}
              width={dimenssion.width * 0.07}
              height={dimenssion.height * 0.03}
              fill="white"
              align="center"
              verticalAlign="middle"
              fontSize={dimenssion.width / 20}
              stroke={"white"}
              strokeWidth={1.2}
            />
            <Rect
              x={dimenssion.width * 0.01}
              y={dimenssion.height * 0.15}
              width={dimenssion.width * 0.07}
              height={dimenssion.height * 0.03}
              fill={win && win.factor == 3 ? "green" : "red"}
              stroke={"yellow"}
            />
            <Text
              text={"x3"}
              x={0}
              y={dimenssion.height * 0.15}
              width={dimenssion.width * 0.07}
              height={dimenssion.height * 0.03}
              fill="white"
              align="center"
              verticalAlign="middle"
              fontSize={dimenssion.width / 20}
              stroke={"white"}
              strokeWidth={1.2}
            />
          </Group>
          {/**Monto Apostado en esta Partida */}
          <Group
            x={dimenssion.width * 0.1}
            y={dimenssion.height * 0.32}
            width={dimenssion.width * 0.1}
            height={dimenssion.height * 0.3}
          >
            <Rect
              x={dimenssion.width * 0.01}
              y={dimenssion.height * 0.15}
              width={dimenssion.width * 0.1}
              height={dimenssion.height * 0.03}
              fill="black"
              stroke={"gray"}
            />
            <Text
              text={amountThisBet}
              x={0}
              y={dimenssion.height * 0.15}
              width={dimenssion.width * 0.1}
              height={dimenssion.height * 0.03}
              fill="white"
              align="center"
              verticalAlign="middle"
              fontSize={dimenssion.width / 20}
            />
          </Group>
        </Layer>
        {/**Esquinero de Maquina */}
        <Layer>
          <Group
            x={0}
            y={0}
            width={dimenssion.width}
            height={dimenssion.height}
          >
            <Line
              points={[
                0,
                0,
                dimenssion.width * 0.05,
                dimenssion.height * 0.03,
                dimenssion.width * 0.05,
                dimenssion.height * 0.97,
                0,
                dimenssion.height,
              ]}
              stroke="gray"
              closed
              fill="#A9A9A9
"
            />
            <Line
              points={[
                0,
                0,
                dimenssion.width * 0.05,
                dimenssion.height * 0.03,
                dimenssion.width * 0.95,
                dimenssion.height * 0.03,
                dimenssion.width,
                0,
              ]}
              stroke="gray"
              closed
              fill="#A9A9A9
"
            />
            <Line
              points={[
                dimenssion.width,
                0,
                dimenssion.width * 0.95,
                dimenssion.height * 0.03,
                dimenssion.width * 0.95,
                dimenssion.height * 0.97,
                dimenssion.width,
                dimenssion.height,
              ]}
              stroke="gray"
              closed
              fill="#A9A9A9
"
            />
            <Line
              points={[
                0,
                dimenssion.height,
                dimenssion.width * 0.05,
                dimenssion.height * 0.97,
                dimenssion.width * 0.95,
                dimenssion.height * 0.97,
                dimenssion.width,
                dimenssion.height,
              ]}
              stroke="gray"
              closed
              fill="#A9A9A9
"
            />
          </Group>
        </Layer>
      </Stage>
    );
  }
};
export default Machine;
