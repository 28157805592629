import React, { useState } from "react";

const LotteryTicket = () => {
  const [inputValue, setInputValue] = useState("");
  const [bets, setBets] = useState([]);
  const [currentBet, setCurrentBet] = useState([]);
  const [enterCount, setEnterCount] = useState(0);
  const [error, setError] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (
        inputValue.length === 2 ||
        inputValue.length === 4 ||
        inputValue.length === 6
      ) {
        setCurrentBet([...currentBet, inputValue]);
        setInputValue("");
        setEnterCount(enterCount + 1);
        setError("");
      } else {
        setError("La longitud del número debe ser 2, 4 o 6.");
      }

      if (enterCount === 1) {
        setBets([...bets, currentBet.join("-")]);
        setCurrentBet([]);
        setEnterCount(0);
      }

      if (enterCount === 2) {
        submitTicket();
      }
    }
  };

  const submitTicket = async () => {
    const ticket = bets.join("\n");
    const totalAmount = bets.length;

    // Simula una llamada al servidor para generar el ticket
    await fetch("/api/generate-ticket", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket, totalAmount }),
    });

    // Aquí puedes añadir la lógica para imprimir el ticket en una impresora térmica
    alert(`Ticket generado:\n${ticket}\nTotal apostado: ${totalAmount}`);
  };

  return (
    <div>
      <h2>Venta de Ticket de Lotería</h2>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <textarea rows="10" cols="30" value={bets.join("\n")} readOnly></textarea>
      <div>
        <p>Total apostado: {bets.length}</p>
      </div>
    </div>
  );
};

export default LotteryTicket;
