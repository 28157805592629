import React from 'react'
import JackpotGral from './Jackpot-Gral'
import JackpotLocal from './jackpot-local'
import './Jackpots.css'
function Jackpots(props) {

  if(props.type=='general'){ 
   return (<div><JackpotGral {...props}/></div>)
  }else{
  return (
 <div><JackpotLocal {...props}/></div> 
  )}
}

export default Jackpots