import { url, headers } from "./const";  
  /**Cierre de se session */
 export const Logout = async()=>{
  try {
    const res = await fetch(`${url}/user/logout`,{
      method: 'GET',
      headers:headers
    })
    const data = await res.json();
    if(data.includes('exito') || data.includes('clos')) {
      localStorage.clear();
      document.location.reload()
    }
  } catch (error) {
    console.log(error)
  }
  
}