import React, { useCallback, useEffect, useState } from "react";

import { Group, Layer, Stage, Rect, Text, Image as KonvaImage } from "react-konva";
import minaImg from '../../assets/venta/mina.png'

const Mina = ()=>{
    const [dim, setDim] = useState({width: window.innerWidth, height: window.innerHeight});
    const [ number, setNumber] = useState([])
    const [gameOver, setGameOver] = useState(false)
    const [mina, setMina] = useState()
    useEffect(()=>{
        const img = new window.Image();
        img.src = minaImg;
        img.onload = setMina(img)
    })
    useEffect(()=>{
                    const jp = Math.ceil(Math.random() * 100);
                    const x2 = Math.ceil(Math.random() * 100);
                    const x3 = Math.ceil(Math.random() * 100);
        for(let i =1; i<=100; i++){
            const value = Math.ceil(Math.random()*10)
           const text = i===jp? 'jp': i===x2?'x2': i===x3?'x3':value
            setNumber(actual =>actual.length>0?[...actual, {num:i, bg:'white', value: text<=3?'':text, op:0.1}]:[{num:i, bg:'white', value: text<=3?'':text, op:0.1}])
        }

    },[number])
    const showValue = useCallback((num)=>{

        number[num].op =0.1
        setNumber(value =>value)
        if (number[num].value =='') {
            number[num].bg ='red'
          setGameOver(true);
        }
    },[number])
return (
  <Stage width={dim.width} height={dim.height}>
    <Layer>
      <Group x={0} y={0} width={dim.width} height={dim.height}>
        <Rect
          x={0}
          y={0}
          width={dim.width * 0.99}
          height={dim.height}
          fill="gray"
        />
        {number.length > 0 &&
          number.map((item, index) => (
            <Group
              key={index}
              x={(dim.width * (index % 10)) / 10}
              y={(dim.height * parseInt(index / 10)) / 10}
              width={dim.width / 10}
              height={dim.height / 10}
              onClick={() => showValue(index)}
              onTouchStart={() => showValue(index)}
            >
              {item.text !== "" ? (
                <Group>
                  <Text
                    x={0}
                    y={0}
                    text={item.value}
                    fontSize={dim.width / 30}
                    width={dim.width / 10}
                    height={dim.height / 10}
                    align="center"
                    verticalAlign="middle"
                    fill="black"
                    onClick={() => showValue(index)}
                    onTouchStart={() => showValue(index)}
                  />
                  <Rect
                    x={0}
                    y={0}
                    width={dim.width / 10.5}
                    height={dim.height / 10.5}
                    fill={item.bg}
                    stroke={"black"}
                    padding={10}
                    cornerRadius={10}
                    opacity={item.op}
                    onClick={() => showValue(index)}
                    onTouchStart={() => showValue(index)}
                  />
                </Group>
              ) : (
                <Group>
                  <KonvaImage
                    Image={mina}
                    x={0}
                    y={0}
                    width={dim.width / 10.5}
                    height={dim.height / 10.5}
                  />
                </Group>
              )}
            </Group>
          ))}
        {gameOver && (
          <Group>
            <KonvaImage
              Image={mina}
              x={0}
              y={0}
              width={dim.width}
              height={dim.height}
            />
            <Rect
              x={0}
              y={0}
              width={dim.width * 0.99}
              height={dim.height}
              fill="gray"
              opacity={0.5}
            />
            <Text
              x={0}
              y={0}
              text="Game Over"
              fontSize={dim.width / 10}
              width={dim.width}
              height={dim.height}
              align="center"
              verticalAlign="middle"
              fill="black"
            />
          </Group>
        )}
      </Group>
    </Layer>
  </Stage>
);
}

export default Mina;