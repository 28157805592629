import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { headers, url } from '../../../utils/const'
import TablePoint from './table-point';

function Point() {
  // const dateInit = useRef(null);
  // const dateEnd = useRef(null);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState({init:'', end:''})
  const [name, setName] = useState('');
  const [customer, setCustomer] = useState('');
  const [point, setPoint] = useState({});
  const [pointList, setPointList] = useState([]);
const [dateReport, setDateReport] = useState()
  const [level, setLevel] = useState(false)
  const [address, setAddress] = useState({
    street: '',
    city: '',
    province: '',
    country: 'Republica Dominicana',
  });
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch(`${url}/point`, {
      method: 'get',
      headers: headers,
    }).then(res => res.json()).then(data => {
      setPointList(data);
    });
    
    if (localStorage.getItem('level') === '1') {
      setLevel(true)
      fetch(`${url}/customer`, {
        method: 'get',
        headers: headers,
      }).then(res => res.json()).then(data => {
        setList(data);
      });
    }else{
      setLevel(false)
    }

    setDateReport({init:dateFilter.init, end: dateFilter.end})

  }, [])
  const OnChangeDateInit = (e) => setDateFilter((value) => {return {...value, init:e.target.value}});
  const OnchangeDateEnd = (e) => setDateFilter((value) => {return {...value, end:e.target.value}});
  const OnChangeCustomer = (e) => setCustomer(e.target.value);
  const OnchangeName = (e) => setName(e.target.value);
  const OnChangeAddressStreet = (e) => setAddress({ ...address, street: e.target.value })
  const OnChangeAddressCity = (e) => setAddress({ ...address, city: e.target.value })
  const OnChangeAddressProvince = (e) => setAddress({ ...address, province: e.target.value })
  const OnChangeAddressCountry = (e) => setAddress({ ...address, country: e.target.value })
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  /**Crear punto */
  const CreatePoint = () => {
    setPoint({ address: address, name: name, customer: customer })
    fetch(`${url}/point`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(point)
    }).then(res => res.json())
      .then(data => {
        if (!data.includes('Error')) {
          setAddress({
            street: '',
            city: '',
            province: '',
            country: 'Republica Dominicana',
          })
          setName('');
          setPoint({});
          setCustomer('');
          handleClose();
        } else {
          // terminar en caso de error
        }
      })
  }
  /**Filtrar */
  const Filtrar = ()=>{
    setDateReport({init:dateFilter.init, end: dateFilter.end})
    fetch(`${url}/point?init=${dateFilter.init}&end=${dateFilter.end}`, {
      method: 'get',
      headers: headers,
    }).then(res => res.json()).then(data => {

      setPointList(data);
      handleCloseFilter();
    }); 
  }
  return (
    <div className='new-point'>
      <div className='col-12 row justify-content-end pt-2 '>
        <div className='col-6 text-dark align-center '><span>De: <strong>{dateFilter.init !==''?new Date(`${dateFilter.init}T00:00`).toLocaleDateString('es-ES'):new Date().toLocaleDateString('es-ES')}</strong> A </span> <span><strong>{dateFilter.end!==''? new Date(`${dateFilter.end}T00:00`).toLocaleDateString('es-ES'):`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}</strong></span></div>
        <div className='col-3'>      <button onClick={handleShow} className="btn btn-primary form-control" disabled={localStorage.getItem('level') != '1'} hidden={!level}>
          Crear un Nuevo Punto de Venta
        </button></div>
        <div className='col-3'>      <button onClick={handleShowFilter} className="btn btn-primary form-control" >
          Filtrar
        </button></div>
      </div>
      <div><TablePoint list={pointList} date ={dateReport} /></div>
      <Modal show={show} onHide={handleClose} size='lg' className='bg-info'>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nuevo Punto</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className='col-12 row'>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Socio</label></div>
                <div className='col-12'>
                  <select className='form-control' value={customer} onChange={OnChangeCustomer}>
                    <option>Seleccione Un Socio</option>
                    {
                      list.length > 0 ? list.map(z => <option key={z._id}>{z.name}</option>) : ''
                    }
                  </select>
                </div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Nombre Del Punto</label></div>
                <div className='col-12'><input type="text" className='form-control' value={name} onChange={OnchangeName}></input></div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Direccion</label></div>
                <div className='col-12'><input type="text" className='form-control' value={address.street} onChange={OnChangeAddressStreet}></input></div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Ciudad:</label></div>
                <div className='col-12'><input type="text" className='form-control' value={address.city} onChange={OnChangeAddressCity}></input></div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Provincia</label></div>
                <div className='col-12'><input type="text" className='form-control' value={address.province} onChange={OnChangeAddressProvince}></input></div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Pais</label></div>
                <div className='col-12'><input type="text" className='form-control' value={address.country} onChange={OnChangeAddressCountry}></input></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose}>
              Close
            </button>
            <button type='button' onClick={CreatePoint}>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showFilter} onHide={handleCloseFilter} size='lg' className='bg-info'>
        <Modal.Header closeButton>
          <Modal.Title>Filtrar Ventas</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className='col-12 row'>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Fecha Inicial</label></div>
                <div className='col-12'><input type="date" className='form-control' value={dateFilter.init} onChange={OnChangeDateInit}></input></div>
              </div>
              <div className='col-6 form-group'>
                <div className='col-12'><label>Fecha Final:</label></div>
                <div className='col-12'><input type="date" className='form-control' value={dateFilter.end} onChange={OnchangeDateEnd}></input></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={handleCloseFilter}>
              Close
            </button>
            <button type='button' onClick={Filtrar}>
              Buscar
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Point