import React, { useEffect, useRef, useState } from "react"
import { Link } from 'react-router-dom';
import { headers, url } from "../../utils/const"
import  Modal from 'react-bootstrap/Modal';
import { ModalBody, ModalHeader } from "react-bootstrap";
import './player.css';
 const AdminPlayer = ()=>{
    const [show, setShow] = useState(false);
    const retiroRef = useRef('');
    const [player, setPlayer] = useState({})
    const [ticket, setTicket] = useState([])
    const [dimenssion, setDimenssion] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimenssion({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimenssion]);

    useEffect(()=>{
        (async()=>{
            const res = await fetch(`${url}/player/info-player`,{method:'get', headers: headers});
            const data = await res.json();
            console.log(data)
            setTicket(()=> data.ticket);
            setPlayer(()=>data.player)
           // retiroRef.current?.value =data && data.player && data.player.credit? data.player.credit.balance.toFixed(2) : 0
        })()
    },[])
    const handleShow = ()=> setShow(true)
    const handleClose = ()=>setShow(false)
      /**Enter Para Pago */
  const EnterPay = (ticket,action) => {
        fetch(`${url}/ticket/pay`, {
          method: 'post',
          headers: headers,
          body: JSON.stringify({ ticket, action })
        }).then(res => res.json())
          .then(data => {
            if (typeof (data) != 'string' && !data.msg) {
                alert(`Cobrado: $${data.amountToPay}`)
            } else if (typeof (data) == 'string') {

            } else if (data.msg && data.msg.includes('agado')) {
                alert('Juga ya ha sido cobrada')
            }else{
              if(data.hasOwnProperty('msg') && data.msg.includes('petid')){
              }
            }
          })
      }
        /**Eliminar Ticket */
  const EliminarTicket = (ticket) => {
    fetch(`${url}/ticket/pay`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({ ticket: ticket, action: 'Delete' })
    }).then(res => res.json())
      .then(data => {
        window.location.reload();
    })
  }
  const RetirarCredito =()=>{
    fetch(`${url}/player/solicitud-retiro`,{method:'post', headers: headers, body: JSON.stringify({player:player, amount:retiroRef.current.value})})
.then(res => res.json())
.then(data =>{
    setShow(false)
    window.location.reload();
})
}
  if (dimenssion.width < dimenssion.height) {
    return (
      <div className="fs-1 text-white">
        Favor colocar el dispositivo de forma Horizontal
      </div>
    );
  } else {
    return<div className=" container-fluid card">
        <div className="card-header col-12">
          <div className="col-12 row justify-content-center">
            <div className="col-3 fs-5">Codigo: {player.code}</div>
            <div className="col-3 fs-5">Credito: RD$. {player && player.credit? player.credit.balance.toFixed(2):''}</div>
            <div className="col-3"><button onClick={handleShow} className="btn btn-warning form-cotrol fs-5">Retirar Credito</button></div>
            </div>
            <div className="row col-12 pt-2">
            <div className="col fs-5"><Link to="/line" className="btn btn-primary form-control fs-5">Perros</Link></div>
            <div className="col fs-5"><Link to="/machine" className="btn btn-primary form-control fs-5">Maquinita</Link></div>
            <div className="col fs-5"><Link to="/slot" className="btn btn-primary form-control fs-5" disabled>Casino</Link></div>
            <div className="col fs-5"><Link to="/sports" className="btn btn-primary form-control fs-5" disabled>Sports</Link></div>
            </div>
        </div>
        <div className="card-body col-12 row justify-content-beetwen">
            <div className="col"><h6>Tickets de PERROS Ultimas 24 Horas</h6></div>
        <div className="table table-responsive">
            <table className="table table-sm">
            <thead className="thead-dark">
                <tr className="tr-admin-player">
                    <th>Num</th>
                    <th>Ticket</th>
                    <th>Jugada</th>
                    <th>Carrera</th>
                    <th>Apostado</th>
                    <th>Ganador</th>
                    <th>Monto Ganado</th>
                    <th>Factor</th>
                    <th>Jackpot</th>
                    <th>Monto Pagado</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
            {ticket? ticket.map((item, index)=><tr className="tr-admin-player" key={index}>
                    <td>{index+1}</td>
                    <td>{item.num}{!item.run.video?<button className="btn btn-danger" onClick={()=>EliminarTicket(item.num)}>X</button>:''}</td>
                    <td>{item.betted.map(x => x.dog).join('\n')}</td>
                    <td>{item.run.num}</td>
                    <td>{item.amount}</td>
                    <td>{item.winner.state===true? 'Ganador':''}</td>
                    <td>{item.winner.state===true? item.winner.amountWinner.toFixed(2):''}</td>
                    <td>{item.winner.state===true? item.winner.factor:''}</td>
                    <td>{item.winner.jackpot.value>0? item.winner.factor:''}</td>
                    <td>{item.winner.state===true? item.winner.amountToPay.toFixed(2):''}</td>
                    <td>{item.winner.state===true? item.winner.paid===true? 'Pagado':<button className="btn btn-success" onClick={()=>EnterPay(item.num,'pay')}>Cobrar</button>:''}</td> 
                </tr>):<tr></tr>}
            </tbody>
            </table>
        </div>
        </div>
        <Modal show={show} size='sm' className='' >
                <ModalBody className="text-center">
                    
                <div className="col-12 m-1 p-1 bg-dark text-white">
                    <div className="col m-1 p-1"><label className="fs-3">Digite el monto a retirar:</label></div>
                    <div className="col m-1 p-1"><input type="number" className="form-control text-center fs-3" ref={retiroRef}></input></div>
                   
                    <div className="col m-1 p-1 row">
                        <div className="col m-1 p-1 row"><button className=" btn btn-warning form-control text-center fs-3" onClick={handleClose}>Cancelar</button></div>
                        <div className="col m-1 p-1 row"><button className=" btn btn-primary form-control text-center fs-3" onClick={RetirarCredito}>Solicitar Retiro</button></div>
                    </div>
                </div>
                </ModalBody>


        </Modal>
    </div>
}
 }
export default AdminPlayer;