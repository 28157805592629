import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Barcode from 'react-jsbarcode';
import "./bet.css";
import { headers, url } from "../../../utils/const";
let print;
const BetSport = () => {
  const [betted, setBetted] = useState([]);
  const inputBetted = useRef("");
  const inputValueThisBetted = useRef("");
  const inputAmoutBetted = useRef("");
  const [show, setShow] = useState(false);
  const [program, setProgram] = useState([]);
  const [ticket, setTicket] = useState();
  const [showModalPay, setShowModalPay] = useState(false)

  // Obtener el valor actual del input, forma la apuesta y la agrega al ticket
  const getInputBetted = () => {
    console.log(inputBetted.current.value);
    console.log(inputValueThisBetted.current.value);
  };
  useEffect(() => {
    (async () => {
      await GetProgram();
    })();
    if (print) {
      window.print();
      print = undefined;
    }
  }, [print]);

  const GetProgram = async () => {
    const response = await fetch(`${url}/pelea`, {
      method: "GET",
      headers: headers,
    });
    const data = await response.json();

    if (data.peleas.length > 0) {
      const peleasResp = data.peleas;
      console.log(peleasResp);
      const peleasMap = peleasResp.map((z) => {
        return {
          num: z.num,
          gallos: {
            blue: {
              traba: z.gladiators.blue.traba.name,
              rate: z.gladiators.blue.rate,
            },
            white: {
              traba: z.gladiators.white.traba.name,
              rate: z.gladiators.white.rate,
            },
          },
          state: z.state,
          code: z.code,
          time: z.stimeTime,
        };
      });
      setProgram(() => peleasMap);
      console.log(program);
    }
  };
  // si se presiona enter
  const handleGetBet = (event) => {
    if (event.key === "Enter") {
      if (inputBetted.current.value !== "") {
        const thisBetInput = inputBetted.current.value;
        const option = thisBetInput.split("").at(-1).toUpperCase();
        const thisBetCode = thisBetInput
          .split("")
          .slice(0, -1)
          .join("")
          .toUpperCase();
        const peleaThisBet = program.find((item) => item.code === thisBetCode);
        console.log(option, thisBetCode, peleaThisBet);
        let rate;
        if (option === "R") {
          rate = peleaThisBet.gallos.white.rate;
        } else if (option === "B") {
          rate = peleaThisBet.gallos.blue.rate;
        } else if (option === "+" || option === "-") {
          rate = -12;
        } else {
          return alert("La Jugada No es Valida");
        }

        if (!peleaThisBet) return alert("La Jugada Digitada No es Valida");
        const thisBetted = {
          number: betted.length + 1,
          code: peleaThisBet.code,
          //coliceo: peleaThisBet.coliceo.name,
          option: option,
          rate,
        };
        if (
          betted.some(
            (item) =>
              item.code === thisBetted.code && item.option === thisBetted.option
          )
        ) {
          alert("Jugada repetida");
        } else {
          setBetted((value) =>
            value.length > 0 ? [...value, thisBetted] : [thisBetted]
          );
        }

        inputBetted.current.value = "";
        inputValueThisBetted.current.focus();
      } else {
        if (betted.length > 0) {
          inputAmoutBetted.current.focus();
        } else {
          //inputBetted.current.focus()
        }
      }
    }
  };
  const handleGetAmountThisBet = (event) => {
    if (event.key === "Enter") {
      //TODO:// Este boton se habilitara cuando se quiera agregar jugadas independiente en un mismo ticket
      //const amountThisBet= inputValueThisBetted.current.value || 0;
    }
  };
  const handleGetAmountBet = (event) => {
    if (event.key === "Enter") {
      if (
        inputAmoutBetted.current.value !== "" &&
        inputAmoutBetted.current.value > 0
      ) {
        const amount = inputAmoutBetted.current.value;
        fetch(`${URL}/ticket/add`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ betted: betted, amount }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.msg == "ok") {
              print = data.ticket;
              setTicket(() => data.ticket);
              setBetted([]);
              inputAmoutBetted.current.value = "";
              inputBetted.current.focus();
            }
          });
      } else {
        if (betted.length > 0) {
          alert("Favor introducir un monto valido");
        } else {
          inputBetted.current.focus();
        }
      }
    }
  };
  const handleShowModalPay = ()=>{
    setShowModalPay(true)
  }
  const handleCloseModalPay = ()=>{
    setShowModalPay(false)
  }
  //Cancelar Jugada Actual
  const CancelarThisBet = () => {
    setBetted([]);
  };

  /**Imprime lineas */
  const PrintLine = () => {
    window.print();
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className="container-fluid col-12 m-0 p-0 row">
      <header className="bg-dark m-0 p-0 col-12 no-print">
        <nav className="col-12 row justify-content-center">
          <div className="col-8 row">
            <div className="col btn btn-primary form-control p-3 m-3 text-white">
              Cuadre Caja
            </div>
            <div
              className="col btn btn-primary form-control p-3 m-3 text-white"
              onClick={handleShow}
            >
              Linea
            </div>
            <div className="col btn btn-primary form-control p-3 m-3 text-white" onClick={handleShowModalPay}>
              Pagar
            </div>
          </div>
          <div className="col-4 row justify-content-end m-0 p-0">
            <div className="col-4 btn btn-danger form-info p-3 m-3 text-white">
              Cerrar
            </div>
          </div>
        </nav>
      </header>
      <div className="col-1"></div>
      <div className="col-10 row mt-3 border justify-content-center rounded-3 no-print">
        <div className=" col-12 row mt-3">
          <div className="col-6">
            <input
              className="form-control"
              ref={inputBetted}
              onKeyDown={handleGetBet}
            />
          </div>
          <div className="col-3">
            <input
              className="form-control"
              type="number"
              ref={inputValueThisBetted}
              onKeyDown={handleGetAmountThisBet}
              disabled
            />
          </div>
          <div className="col-3">
            <button
              type="button"
              className="btn btn-primary form-control"
              onClick={getInputBetted}
            >
              Agregar
            </button>
          </div>
        </div>
        <div className="col-12 table-responsive mt-3">
          <table className="table table-bordewhite">
            <thead>
              <tr>
                <th>Número</th>
                <th>Codigo</th>
                <th>Apuesta</th>
                <th>Linea</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {betted.length > 0 ? (
                betted.map((item, index) => (
                  <tr key={index}>
                    <td>{item.number}</td>
                    <td>{item.code}</td>
                    <td>
                      {item.option === "B"
                        ? program.find((y) => y.code === item.code).gallos.blue
                            .traba
                        : item.option === "+" || item.option === "-"
                        ? `${
                            program.find((y) => y.code === item.code).gallos
                              .blue.traba
                          } ${item.option}${item.stimeTime}`
                        : program.find((y) => y.code === item.code).gallos.white
                            .traba}
                    </td>
                    <td>{item.rate}</td>
                    <td>
                      <button type="button" className="btn ">
                        X
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
          <div className="col-12 row mb-3 justify-content-end mr-0 pr-0">
            <div className="col-4 mr-0 pr-0 justify-content-end">
              <label htmlFor="" className="label fs-4">
                Monto Apostado
              </label>
            </div>
            <div className="col-3 mr-0 pr-0">
              <input
                type="number"
                className="form-control"
                ref={inputAmoutBetted}
                onKeyDown={handleGetAmountBet}
              />
            </div>
          </div>
          <div className="col-12 row mb-3 mr-0 pr-0">
            <div className="col-6">
              {" "}
              <button
                type="button"
                className="btn btn-warning form-control"
                onClick={CancelarThisBet}
              >
                {" "}
                Cancelar
              </button>
            </div>
            <div className="col-6">
              {" "}
              <button type="button" className="btn btn-primary form-control">
                Imprimir
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} className="modal">
        <ModalHeader>
          <div>Linea</div>
          <div className="btn btn-close no-print" onClick={handleClose}></div>
        </ModalHeader>
        <ModalBody className="print">
          <div>GALLOS SPORT</div>
          <div>Peleas de {new Date().toLocaleDateString("es-ES")}</div>
          <div className="container-fluid">
            <div className="col-12 row justify-content-between">
              <div className="col-1 text-center bl">BL</div>
              <div className="col-1 text-center pl">PL</div>
              <div className="col-6 text-center trabas">TRABAS</div>
              <div className="col-2 text-center">LINEA</div>
              <div className="col-2 text-center time">TIEMPO</div>
            </div>
            {program && program.length > 0 ? (
              program.map((z, index) => (
                <div className="col-12 row justify-content-between">
                  <div className="col-1 text-center bl">{z.num}</div>
                  <div className="col-1 text-center bl">{z.code}</div>
                  <div className="col-6 text-center trabas">
                    <div className="col-12 text-center">
                      {z.gallos.blue.traba}
                    </div>
                    <div className="col-12 text-center">
                      {z.gallos.white.traba}
                    </div>
                  </div>
                  <div className="col-2 text-center bl">
                    <div>{z.gallos.blue.rate}</div>
                    <div>{z.gallos.white.rate}</div>
                  </div>
                  <div className="col-2 text-center time">{z.time}</div>
                  <hr />{" "}
                </div>
              ))
            ) : (
              <div>No Hay Peleas Disponibles</div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="no-print">
          <div className="col-12 row">
            <div className="col justify-content-end">
              {" "}
              <button className="btn form-control">Cerrar</button>
            </div>
            <div className="col justify-content-end">
              {" "}
              <button
                className="btn btn-primary form-control"
                onClick={PrintLine}
              >
                Imprimir
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <div className="print">
        {print ? (
          <div className="continer-fluid justify-content-between row">
            <Barcode value="1234567" options={{height:20, displayValue:false}}/>
            <div className="col-12 text-center m-1 fs-3">Nombre Del Punto</div>
            <div className="col-6">Ticket:{print.num}</div>
            <div className="col-6">
              Fecha:{new Date(print.date).toLocaleTimeString("es-ES")}
            </div>
            <div className="col-12 ">
              <table className="table">
                <thead>
                  <th>Num</th>
                  <th>Codigo</th>
                  <th>Opcion</th>
                  <th>Paga</th>
                </thead>
            <tbody>
             {print.betted.map((z, index)=> <tr>
                <td>{index}</td>
                <td>{z.pelea}</td>
                <td>{z.option}</td>
                <td>{z.rate>0?'+'+z.rate:z.rate}</td>
              </tr>)}
            </tbody>
              </table>
            </div>
            <div className="col-2"></div>
            <div className="col-10 row">
              <div className="col-8">Apostado (RD$.)</div>
              <div className="col-4">{print.amount.toLocaleString('en-EN')}</div>
              <div className="col-8">Gana (RD$.)</div>
              <div className="col-4">{parseInt(print.winner.amountWinner).toLocaleString('en-EN')}</div>
            </div>
          </div>
        ) : (
          "No Print"
        )}
      </div>
      <Modal show={showModalPay}>
        <ModalHeader className="bg-dark m-0 p-0 text-white text-center">
          <h4>Verificar Para pago de ticket</h4>
          <button className="btn btn-close bg-white"></button>
          <div></div>
        </ModalHeader>
        <ModalBody>
          <div className="col-12 row justify-content-between">
            <div className="col-6 text-end fs-3">Digite el ticket:</div>
            <div className="col-6"><input type="text"  className="form-control border border-secondary"/></div>
            <div className="mt-2 border border-secondary rounded-2"></div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 row justify-content-between">
            <div className="col-4"><button type="button" className="form-control btn btn-warning" onClick={handleCloseModalPay}>Cancelar</button></div>
            <div className="col-4"><button type="button" className="form-control btn btn-primary">Verificar</button></div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

/*

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        inputBetted.current.value !== "" &&
        document.activeElement === inputBetted.current
      ) {
        inputValueThisBetted.current.focus();
      } else if (
        inputBetted.current.value !== "" &&
        document.activeElement === inputValueThisBetted.current
      ) {
        // TODO:: Agrega la nueva jugada al ticket
        const coliceo = inputBetted.current.value.split("")[0].toUpperCase();
        const pelea = inputBetted.current.value.split("").slice(0, -1).join("");
        const option = inputBetted.current.value.split("").at(-1).toUpperCase();

        console.log(coliceo, pelea, option);

        if (coliceo.toUpperCase() === "R" || coliceo.toUpperCase() === "P") {
          // TODO:: validar si la pelea aun esta activa y si la opcion de apuesta es correcta. manda a buscar la cartelera al backend
          const thisPelea = program.find(item=> item.code=== pelea)
          console.log(thisPelea)
          if (thisPelea && thisPelea.state==='active') {
            if (1 === 1) {
              const newBetted = {
                number: betted.length + 1,
                coliceo: thisPelea.coliceo.name,
                pelea,
                option,
                betted:
                  inputValueThisBetted.current.value === ""
                    ? 0
                    : inputValueThisBetted.current.value,
                code: thisPelea.code,
              };
              setBetted((value) =>
                value.length > 0 ? [...value, newBetted] : [newBetted]
              );
              console.log(betted);
              // Limpia
              inputBetted.current.value = "";
              inputValueThisBetted.current.value = "";
              inputBetted.current.focus();
            } else {
              return alert("Opcion de Apuesta No Disponible");
            }
          } else {
            return alert("Pelea No Valida o No Activa");
          }
        } else {
          return alert("Seleccione un Coliceo Correcto");
        }
      } else if (
        inputBetted.current.value === "" &&
        inputValueThisBetted.current.value === "" &&
        betted.length > 0 &&
        document.activeElement !== inputAmoutBetted.current
      ) {
        // Activa el campo para introducir el monto apostar
        inputAmoutBetted.current.focus();
        console.log("1");
      } else if (
        inputBetted.current.value === "" &&
        inputValueThisBetted.current.value === "" &&
        betted.length === 0 &&
        document.activeElement === inputAmoutBetted.current
      ) {
        console.log("aca");
      } else {
        console.log("alla");
      }
    }
  };
*/
export default BetSport;