import React, { useState, useEffect, useRef} from "react";
import { Logout } from "../../utils/logout";
import { DoorOpen, DoorOpenFill } from "react-bootstrap-icons";
import Barcode from "react-jsbarcode";
import { socket } from "../../io";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { addSeconds } from "date-fns";
import { headers, url } from "../../utils/const";

function LoteryBet() {
  const [inputValue, setInputValue] = useState("");
  const [firstEnter, setFirstEnter] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [numberInputValue, setNumberInputValue] = useState("");
  const [currentBet, setCurrentBet] = useState([]);
  const [error, setError] = useState("");
  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lastChar = value.slice(-1);
    if (/^\d*$/.test(lastChar) || (firstEnter && lastChar === "$")) {
      setInputValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue==''){
     fetch(`${url}/lotery-bet`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify(currentBet)
     }).then(res => res.json())
     .then(data =>{
      console.log(data)
     })
    }
      if (e.key === "Enter") {
        
        if (firstEnter === false) {
          setInputValue((prev) => `${prev}$`);
          setFirstEnter(true);
        } else {
          if (firstEnter === true) {
            const [firstPart, secondPart] = inputValue.split("$");
            if (
              firstPart.length === 2 ||
              firstPart.length === 4 ||
              firstPart.length === 6
            ) {
              const spacedFirstPart = firstPart.match(/.{1,2}/g).join(" ");
              const newText = `$${secondPart} ${spacedFirstPart.length==8?'T ':spacedFirstPart.length==5?'P ':'Q '}${spacedFirstPart}`;
              setTextareaValue((prev) => `${prev}\n${newText}`);
              setCurrentBet((bet)=> [...bet, {amount:parseFloat(secondPart), type: spacedFirstPart.length==8?'T ':spacedFirstPart.length==5?'P ':'Q ', bet:spacedFirstPart}])
              setInputValue("");
              setFirstEnter(false);
            } else {
              setError("La longitud del número debe ser 2, 4 o 6.");
            }
          }
        }
      }
      
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleNumberInputChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setNumberInputValue(value);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textareaValue]);

  return (
    <div className="container-fluid row p-3 justify-content-start">
      <div className="">
        <header>
          <nav>
            <div className="">
              <Link className="vol venta" to="/bet">
                VENTA
              </Link>
              <Link to="/line" className="vol lin">
                LINEA
              </Link>
              <Link className="vol res" to="/history">
                RESULTADOS
              </Link>
              <Link className="vol volvent" to="/venta">
                VOLUMEN DE VENTAS
              </Link>
              <Link className="vol volvent" to="/player">
                JUGADORES
              </Link>
              <button className="boton_menu bg-danger" onClick={Logout}>
                <DoorOpenFill />
              </button>
            </div>
          </nav>
        </header>
        <div className="suspendid">
          <h1 className="carrAc">Carrera Activa...</h1>
        </div>
      </div>
      <div className="col-12 p-0 m-0 row p-3 justify-content-start">
        <div className="col-4 p-0 m-0 form-group">
          {" "}
          <input
            type="text"
            className="form-control"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{ marginRight: "10px" }}
          />
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary form-control"
            onClick={() => console.log("Button next to first input clicked")}
          >
            Agregar
          </button>
        </div>
      </div>
      <div className="col-12 p-0 m-0 row justify-content-end form-group">
        <div className="col-6 row justify-content-end">
          <div className="col-8 p-0 m-0">
            <input
              className="input form-control"
              type="text"
              value={numberInputValue}
              onChange={handleNumberInputChange}
              style={{ marginRight: "10px" }}
            />
          </div>
          <div className="col-4">
            <button
              className="btn form-control btn-success"
              onClick={() => console.log("Button next to number input clicked")}
            >
              Pagar/Eliminar
            </button>
          </div>

          <div className="col-12 p-0 m-0 pt-3 mt-3">
            <textarea
              className="form-control p-0 m-0 pt-3 mt-1"
              ref={textareaRef}
              value={textareaValue}
              onChange={handleTextareaChange}
              style={{ width: "100%", resize: "none" }}
            />
          </div>
          <div className="col-12"><span className="text-white fs-1 text-center">Monto APostado: ${currentBet.map(item=> item.amount).reduce((acum, current)=> acum+current,0)}</span></div>
          <div className="col-3 p-0 m-0 ">
            <button
              className="form-control btn btn-warning"
              style={{ marginTop: "10px" }}
              onClick={() => console.log("Button below textarea clicked")}
            >
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoteryBet;
