import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Group, Wedge, Circle, Image as KonvaImage, Arrow, Rect, Line,  Text} from "react-konva";
import ruleta from "../../assets/venta/ruleta.png";
const numeros = [
  { value: 0, index: 0, bet: 0, color: "green" },
  { value: 32, index: 1, bet: 0, color: "black" },
  { value: 15, index: 2, bet: 0, color: "red" },
  { value: 19, index: 3, bet: 0, color: "red" },
  { value: 4, index: 4, bet: 0, color: "black" },
  { value: 21, index: 5, bet: 0, color: "red" },
  { value: 2, index: 6, bet: 0, color: "black" },
  { value: 25, index: 7, bet: 0, color: "red" },
  { value: 17, index: 8, bet: 0, color: "red" },
  { value: 34, index: 9, bet: 0, color: "black" },
  { value: 6, index: 10, bet: 0, color: "black" },
  { value: 27, index: 11, bet: 0, color: "red" },
  { value: 13, index: 12, bet: 0, color: "red" },
  { value: 36, index: 13, bet: 0, color: "black" },
  { value: 11, index: 14, bet: 0, color: "black" },
  { value: 30, index: 15, bet: 0, color: "black" },
  { value: 8, index: 16, bet: 0, color: "black" },
  { value: 23, index: 17, bet: 0, color: "red" },
  { value: 10, index: 18, bet: 0, color: "black" },
  { value: 5, index: 19, bet: 0, color: "red" },
  { value: 24, index: 20, bet: 0, color: "black" },
  { value: 16, index: 21, bet: 0, color: "red" },
  { value: 33, index: 22, bet: 0, color: "black" },
  { value: 1, index: 23, bet: 0, color: "red" },
  { value: 20, index: 24, bet: 0, color: "black" },
  { value: 14, index: 25, bet: 0, color: "red" },
  { value: 31, index: 26, bet: 0, color: "black" },
  { value: 9, index: 27, bet: 0, color: "red" },
  { value: 22, index: 28, bet: 0, color: "black" },
  { value: 18, index: 29, bet: 0, color: "red" },
  { value: 29, index: 30, bet: 0, color: "black" },
  { value: 7, index: 31, bet: 0, color: "red" },
  { value: 28, index: 32, bet: 0, color: "black" },
  { value: 12, index: 33, bet: 0, color: "red" },
  { value: 35, index: 34, bet: 0, color: "black" },
  { value: 3, index: 35, bet: 0, color: "red" },
  { value: 26, index: 36, bet: 0, color: "black" },
];

const Ruleta = () => {

  const stageWidth = window.innerWidth;
  const stageHeight = window.innerHeight;
  const [ruletaImage, setRuletaImage] = useState(); // Update the path to your image
  const [finalNumber, setFinalNumber] = useState();
useEffect(()=>{
const img = new window.Image();
img.url = ruleta;
img.onload = setRuletaImage(img)
},[])

  const radius = stageHeight/2.5;
  const numSegments = 37;
  const angle = 360 / numSegments;
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const animationRef = useRef(null);

  const colors = ["green", "red", "black"];

  const getColor = (index) => {
    if (index === 0) return colors[0];
    return index % 2 === 0 ? colors[2] : colors[1];
  };

  const segments = numeros.map(({value},i) => (
    <Group>
      <Wedge
        key={i}
        x={0}
        y={0}
        radius={radius}
        angle={angle}
        fill={getColor(i)}
        rotation={i * angle - 90 - angle / 2} // Adjust rotation for segment
        opacity={1}
      />
      <Text
        text={value}
        x={
          0.9 *
          (radius * Math.cos((2 * i * Math.PI) / numSegments - Math.PI / 2))
        }
        y={
          0.9 *
          (radius * Math.sin((2 * i * Math.PI) / numSegments - Math.PI / 2))
        }
        fill={"white"}
        width={(0.9 * radius * angle * Math.PI) / 360}
        fontSize={stageHeight / 40}
        stroke={"white"}
        strokeWidth={2}
        verticalAlign="middle"
        rotation={i * angle - 90 - angle / 2}
      />
    </Group>
  ));


  const spinWheel = () => {
    if (isSpinning) return;
    setIsSpinning(true);
    setSelectedSegment(null);
    const totalRotations = 4;
    const targetRotation = Math.random() * 360 + totalRotations * 360;
    const duration = 15000; // duration of the spin in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      if (elapsed < duration) {
        const currentRotation = targetRotation * (elapsed / duration);
        setRotation(currentRotation);
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setRotation(targetRotation);
        setIsSpinning(false);
        const finalRotation = targetRotation % 360;
        const segmentIndex = Math.floor(
          (numSegments - (finalRotation / 360) * numSegments) % numSegments
        );
        setSelectedSegment(segmentIndex);
    
      }
    };

    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Stage x={0} y={0} width={stageWidth} height={stageHeight}>
        <Layer>
          <Group x={0} y={0} width={stageWidth} height={stageHeight}>
            <Rect
              x={0}
              y={0}
              width={stageWidth}
              height={stageHeight}
              fill={"blue"}
            />
            {[0,1,2,3,4,5,6,7,8,9,10,11,12].map((item, index)=>
                <Wedge
                x={250}
                y={250}
                radius={100}
                angle={360/13}
                rotation={360*index/13 -90 -360/26}
                fill={index==0? 'green':index%2==0? 'red':'black'}
                />
            )}
            <Group
              x={stageWidth / 2}
              y={stageHeight / 2}
              width={stageHeight / 2}
              height={stageHeight / 2}
            >
              <Group x={0} y={0} rotation={rotation}>
                {segments}
                <KonvaImage
                  x={stageWidth / 2}
                  y={stageHeight / 2}
                  width={2 * radius}
                  image={ruletaImage}
                />
                <Circle radius={radius} stroke="gray" strokeWidth={10} />
                <Circle radius={radius*0.8} stroke="gray" strokeWidth={10}/>
                <Circle radius={radius*0.4} stroke="gray" strokeWidth={10}  fill={'brown'}/>
              </Group>
              <Group x={0} y={0}>
                <Line
                  x={0}
                  y={-radius * 1.1}
                  points={[-30, 0, 30, 0, 0, 50]}
                  fill="white"
                  pointerAtBeginning={true}
                  closed
                />
              </Group>
            </Group>
            <Group                 
                x={stageWidth*.7}
                y={stageHeight*0.7}
                >
                <Rect
                x={0}
                y={0}
                width={stageHeight*0.2}
                height={stageHeight*0.1}
                onClick={spinWheel}
                fill={'green'}
                />
                <Text
                x={0}
                y={0}
                text={`Selected Segment: ${selectedSegment}`}
                onClick={spinWheel}
                />
            </Group>
          </Group>
        </Layer>
      </Stage>
    </div>
  );
};

export default Ruleta;
